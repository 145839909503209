import BigNumber from "bignumber.js";
import { DiscountCode, DiscountType } from "./DiscountCode";
import ExtraToolBookingPriceElement from "./ExtraToolBookingPriceElement";

export default class ExtraToolBooking {
  id: string;
  priceElements: ExtraToolBookingPriceElement[];
  total: string;
  createdAt: string;
  bookingOrder_id: string;
  extraTool_id: string;
  taxMode: string;
  taxRate: string;
  discountCode_id: number;
  discountCode: DiscountCode;
  qty?:number;
  constructor() {}
  getBaseTotal() {
    console.log(this.getTotal(), this.getDiscountAmount().toNumber());
    return new BigNumber(this.getTotal())
      .minus(this.getDiscountAmount())
      .toNumber();
  }
  getPriceElement(taxMode, taxRate, qty, price) {
    let total = qty * price;
    if (taxMode != ExtraToolBookingTax.NoTax) total += (total * taxRate) / 100;
    return total;
  }
  getQty() {
    return this.priceElements.map((p) => p.qty).reduce((a, b) => a + b);
  }
  getTotal() {
    return this.total;
  }
  public getDiscountAmount(): BigNumber {
    console.log(this.discountCode);
    if (!this.discountCode) {
      return new BigNumber(0);
    }
    if (this.discountCode.type === DiscountType.FIXED_AMOUNT) {
      return new BigNumber(this.discountCode.amount);
    }

    return new BigNumber(this.discountCode.amount)
      .multipliedBy(this.getTotal())
      .dividedBy(100);
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.total = input.total;
    this.createdAt = input.createdAt;
    this.bookingOrder_id = input.bookingOrder_id;
    this.extraTool_id = input.extraTool_id;
    this.taxMode = input.taxMode;
    this.taxRate = input.taxRate;
    this.discountCode_id = input.discountCode_id;
    this.discountCode = new DiscountCode().deserialize(input.discountCode);

    this.priceElements = JSON.parse(input.priceElements)
    .map(p=>Object.assign(new ExtraToolBookingPriceElement(),p));   
    console.log("P",this.priceElements)
    return this;
  }
}
export const ExtraToolBookingTax = {
  NoTax: 0,
  PlusTax: 1,
  IncludeTax: 2,
};
