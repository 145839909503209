export default class ExtraTool{
    id: string;
    name: string;
    description: string;
    imagePath: string;
    availableAllCourses:boolean;
    available:boolean;
    taxMode:number;
    constructor(){
        this.taxMode = 0;
    }
    deserialize(input) {
        if (!input) {
          return null;
        }
        this.id = input.id;
        this.name = input.name;
        this.description = input.description;
        this.imagePath = input.imagePath;
        this.availableAllCourses = input.availableAllCourses;
        this.available = input.available;
        this.taxMode = input.taxMode; 
        return this;
      }
}