import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {ApiService} from '../../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Country} from '../../../../models/Country';
import {Currency} from '../../../../models/Currency';
import {compareFunc} from '../../../utils/Utils';
import {CustomerCompany} from '../../../../models/CustomerCompany';
import {SystemCompanyService} from '../../../_services/system-company.service';
import {finalize, map, share, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Contact} from '../../../../models/Contact';
import {Company} from '../../../../models/Company';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'customer-company-edit',
  templateUrl: './customer-company-edit.component.html',
  styleUrls: ['./customer-company-edit.component.scss']
})
export class CustomerCompanyEditComponent implements OnInit, OnDestroy {

  @Output() companyAdded: EventEmitter<any> = new EventEmitter();
  @Input() isPartner: boolean;

  @ViewChild('companyForm', {static: true}) companyForm;
  isRequesting = false;
  isEdit = false;
  customerCompany: CustomerCompany;

  compareFunc = compareFunc;

  countries$: Observable<Country[]>;
  currencies$: Observable<Currency[]>;
  contacts$: Observable<Contact[]>;
  public foundAllowedContact$: Observable<Contact[]>;
  public allowedContactSearchValue: string;
  public foundOwnerContact$: Observable<Contact[]>;
  public ownerSearchValue: string;

  private currentCompany: Company;
  private companies$: Observable<Company[]>;
  destroy$: Subject<boolean>;

  constructor(private apiSvc: ApiService,
              private route: ActivatedRoute,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private toastySvc: ToastrService,
              private companySvc: SystemCompanyService) {
    this.destroy$ = new Subject<boolean>();
    this.customerCompany = new CustomerCompany();


  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit() {
    this.countries$ = this.apiSvc.getCountries().pipe(share());
    this.currencies$ = this.apiSvc.getCurrencies().pipe(share());
    this.companies$ = this.apiSvc.getCompanies().pipe(map(companyCollection => companyCollection.data
    ));   
    const currentCompany$ = this.companySvc.getCompany().pipe(
      tap(company => {
        // console.log(company)

        this.customerCompany.billingAddress.country.code =
          company.preferredCountry.code;
      }),
      shareReplay());


    combineLatest(
      [
        this.route.params,
        currentCompany$,
      ]
    ).pipe(
      switchMap(([params, company]) => {
        if (!params.id) {
          const customerCompany = new CustomerCompany();
         
          customerCompany.company = company;

          return of(customerCompany);
        }
        this.isEdit = true;
        return this.apiSvc.getCustomerCompany(params.id).pipe(tap(c=>console.log(c)));
      }),
      takeUntil(this.destroy$)
    )
      .subscribe((company) => {
      

        this.customerCompany = company;
        //this.customerCompany.isPartner = this.isPartner;
        //console.log('customerCompany',this.customerCompany);
        if (this.customerCompany.owner != null)
          this.ownerSearchValue = this.customerCompany.owner.firstName + ' ' + this.customerCompany.owner.lastName;

      });


    this.contacts$ =
      currentCompany$.pipe(
        tap(company => this.currentCompany = company),
        switchMap(company =>

          this.apiSvc.getContacts({'no-page': true, jsonQuery: {'company.id': company.id}, groups: ['contactSummary'], enabled: true})
        ),
        map(contactCollection => contactCollection.data)
      );

    this.foundAllowedContact$ = currentCompany$
      .pipe(
        switchMap((company) => {
          let params: any = {order: ['firstName', 'lastName'], 'no-page': true, jsonQuery: null, groups: ['contactSummary'], enabled: true};

          params.jsonQuery = {
            ...params.jsonQuery,
            'company.id': company.id,
          };

          if (this.allowedContactSearchValue) {
            params.jsonQuery = {
              ...params.jsonQuery,
              $or: [
                {firstName: {$contains: this.allowedContactSearchValue}},
                {lastName: {$contains: this.allowedContactSearchValue}},
              ]
            }
          } else {
            delete (params['no-page']);
            params = {
              ...params,
              itemsPerPage: 5
            }
          }

          return this.apiSvc.getContacts(params);
        }),
        map(contactCollection => contactCollection.data)
      );

    this.foundOwnerContact$ = currentCompany$
      .pipe(
        switchMap((company) => {
          let params: any = {order: ['firstName', 'lastName'], 'no-page': true, jsonQuery: null, groups: ['contactSummary'], enabled: true};

          params.jsonQuery = {
            ...params.jsonQuery,
            'company.id': company.id,
          };

          if (this.ownerSearchValue) {
            params.jsonQuery = {
              ...params.jsonQuery,
              $or: [
                {firstName: {$contains: this.ownerSearchValue}},
                {lastName: {$contains: this.ownerSearchValue}},
              ]
            }
          } else {
            delete (params['no-page']);
            params = {
              ...params,
              itemsPerPage: 5
            }
          }

          return this.apiSvc.getContacts(params);
        }),
        map(contactCollection => contactCollection.data)
      );
  }


  onSubmit() {
    // this.isRequesting = true;
    //this.customerCompany = Object.assign(this.customerCompany, this.companyForm.value);
    //console.log(this.customerCompany);
    this.customerCompany.company = this.currentCompany;
    if(!this.customerCompany.billingAddress.country.code)
    {
      this.toastySvc.error('Please choose Country.');
      return;
    }
    if (this.customerCompany.owner == null) {
      this.toastySvc.error('Please choose company owner.');
      return;
    }
    // console.log(this.customerCompany);
    // return;
    let apiObservable = this.apiSvc.createCustomerCompany(this.customerCompany);

    if (this.isEdit) {
      apiObservable = this.apiSvc.updateCustomerCompany(this.customerCompany);
    }

    apiObservable.pipe(
      finalize(() => {
        this.isRequesting = false;
      })
    ).subscribe(
      (customerCompany) => {
        //the update was successful
        this.toastySvc.info(
          `The Company has been ${this.isEdit ? 'updated' : 'created'} successfully`,
          `${this.isEdit ? 'Updated' : 'Created'}`,
        );
        if (this.customerCompany) {
          this.companyAdded.emit(customerCompany);
          //console.log('companye added ');
        } else if (!this.isEdit) {
          this.router.navigate(['..', customerCompany.id], {relativeTo: this.route})
        }
      },
      (error) => {
        //console.log(error);
        const errorData = {
          title: 'Failed',
          msg: 'Operation failed'
        };

        if (error.error && error.error.description) {

          errorData.msg = `Failed to save changes: ${error.error.description}`;
        }
        this.toastySvc.error(
          errorData.msg,
          errorData.title
        );
      }
    );
  }

  compareFn(key) {
    return compareFunc(key);
  }
}
