import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Contact } from 'models/Contact';

@Injectable({
  providedIn: 'root'
})
export class ApiUsersService {

  private baseUrl2: string;
  constructor(private http: HttpClient ) {
     this.baseUrl2 =   environment.api2Url+"/users";
  }
  getUserById(userId:number){
    return this.http.get<Contact[]>(`${this.baseUrl2}/${userId}`);
  }
}
