import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ApiService} from '../../../_services/api.service';
import {CourseSession} from '../../../../models/CourseSession';
import {forkJoin, Observable, of, Subject, Subscription} from 'rxjs';


import {Router} from '@angular/router';
import {BookingOrderCollection} from '../../../../models/Collections/BookingOrderCollection';
import {InvoiceCollection} from '../../../../models/Collections/InvoiceCollection';
import {CourseRun} from '../../../../models/CourseRun';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';


import {ConfirmModalComponent, ConfirmModalReason} from '../../../theme/components/confirmModal/confirm-modal.component';
import {catchError, map, retry, shareReplay, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ParticipantStatusModalComponent} from '../participant-status-modal/participant-status-modal.component';
import {BookingParticipant} from '../../../../models/BookingParticipant';
import * as moment from 'moment';
import 'moment-timezone/index';
import {ToastrService} from 'ngx-toastr';
import {DragulaOptions, DragulaService} from 'ng2-dragula';
import {Resource} from '../../../../models/Resource';
import {PriceElement} from '../../../../models/PriceElement';
import {PriceElementModal} from '../../course/course-edit/priceElementModal/price-element-modal';
import BigNumber from 'bignumber.js';
import {StaffMember} from '../../../../models/StaffMember';
import { ApiWaiverService } from 'app/_services/api-waiver.service';
import { ApiBookingOrderService, ApiCourseRunService, AuthenticationService } from 'app/_services';
import { Permissions } from 'app/_constants/Permissions';
import { UserType } from 'app/_constants/UserType';
import { CourseRunBooking } from 'models/CourseRunBooking';
import { CourseRunBookingViewModel } from 'app/viewModels/CourseRunBookingViewModel';

@Component({
  selector: 'app-course-event-modal',
  templateUrl: './course-event-modal.component.html',
  styleUrls: ['./course-event-modal.component.scss']
})
export class CourseEventModalComponent implements OnInit, OnDestroy {
  changesMade: boolean = false;

  public tabsEnum = CourseEventModalTabs;
  public title: string;
  staffMembers: any[] = [];

  tabSelected = CourseEventModalTabs.info;
  pendingOrder$: Observable<any>;
  @ViewChild('form', {static: true}) form: NgForm;
  public participants: any[];
  private destroy$: Subject<any> = new Subject<any>();
  public courseRun: CourseRun;
  public sessionChangePending: boolean;
  nonParticipants: any[];
  resources: Resource[] = [];
  priceElements: PriceElement[] = [];
  courseSession$: Observable<any>;
  loadingEvent: boolean = false;
  subs: Subscription = new Subscription();
  showSignIn=false;
  showSignOut = false;
  signInForm:FormGroup;
  signOutForm:FormGroup;
  leadBooker: any;
  currentUser: any;
  canDeleteCourseRun: boolean;
  $extraTools: Observable<Object>;
  _isReadOnly = false;
  @Input() set isReadOnly(value:boolean){
    this._isReadOnly = value;
    if(value){
      setTimeout(()=>{
        this.subs.unsubscribe();
         this.dragulaSvc.destroy('modalStaffAllocation');
        this.dragulaSvc.destroy('modalResourceAllocation');
        
      },2000)
    }
  }
  get isReadOnly(){
    return this._isReadOnly;
  }

  constructor(public bsModalRef: BsModalRef,
              private apiSvc: ApiService,
              private router: Router,
              private modalSvc: BsModalService,
              private toastySvc: ToastrService,
              private dragulaSvc: DragulaService,
              private apiWaiver:ApiWaiverService,
              private _fb : FormBuilder,
              private authSvc: AuthenticationService,
              private apiBookingOrder:ApiBookingOrderService,
              ) {
   
    this.initDragula()
    this.signInForm = this._fb.group({        
      signInDate:[],
      signInMissAction:['',Validators.required],
      signInContactNumber:['',Validators.required],
      signInTimeOfReturn:['',Validators.required],
      courseRunBookingId:[null]
    });
    this.signOutForm = this._fb.group({        
      signOutNotes:["",Validators.required],
      signOutDate:[""],
      courseRunBookingId:[null]
    });
    
  }
  initDragula(){
    console.log("isReadOnly2",this.isReadOnly)  
    this.dragulaSvc.createGroup('modalStaffAllocation', {
      copy: true,
      copyItem: (item: StaffMember) => {
        return new StaffMember().deserialize(item);
      },
      accepts: (el, target) => {
        if (target.className == 'modalAssignedStaff') {
          const staffId = +(<HTMLElement>el).dataset.staffId;
          return !this.event.assignedStaffMembers.find((staffMember) => {
            return staffMember.id == staffId;
          })
        }

        return true;
      },
      invalid: (el) => {
        return el.parentElement.classList.contains('modalAssignedStaff');
      }
    })
    
    this.dragulaSvc.createGroup('modalResourceAllocation', {
      copy: true,
      copyItem: (item: Resource) => {
        return new Resource().deserialize(item);
      },
      accepts: (el, target) => {
        if (target.className == 'modalAssignedResource') {
          const staffId = +(<HTMLElement>el).dataset.resourceId;
          return !this.event.assignedResources.find((staffMember) => {
            return staffMember.id == staffId;
          })
        }

        return true;
      },
      invalid: (el) => {
        return el.parentElement.classList.contains('modalAssignedStaff');
      }
    })
     
    this.subs.add(
      this.dragulaSvc.drop('modalStaffAllocation').pipe(
        switchMap((value) => {
          const {el, target, source} = value;

          if (!target) {
            return of(null);
          }
          if (target.className.indexOf('modalResourceList') > -1) {

          }

          if (target.className.indexOf('modalAssignedStaff') > -1) {
            if (target == source) {
              //this is just a reorder or something..we don't care about this
              return of(null);
            }
            let staffID = +(<HTMLElement>el).dataset.staffId;
            if ((isNaN(staffID) || staffID === undefined) && el.childNodes.length > 0) {
              staffID = +(<HTMLElement>el.childNodes[0]).dataset.staffId;
            }
            const member = this.event.assignedStaffMembers.find(staffMember => staffMember.id == staffID);
            let toSend = {
              id: this.event.id, assignedStaffMembers: this.event.assignedStaffMembers.map(staffMember => {
                return {id: staffMember.id}
              })
            };
            if (this.courseRun.courseSessions.length > 1) {

              const modal = this.modalSvc.show(ConfirmModalComponent);
              const component = <ConfirmModalComponent>modal.content;

              component.message = 'This courseRun has more than one session, do you want to assign this staff member to all the sessions of this courseRun?';
              component.yesText = 'Yes';
              component.noText = 'No';


              return this.modalSvc.onHidden.pipe(
                take(1),
                switchMap(reason => {
                  if (reason !== ConfirmModalReason.YES) {
                    return this.apiSvc.updateCourseSession(toSend).pipe(
                      catchError(x => {
                        this.toastySvc.error('Failed to update allocation');
                        this.event.assignedStaffMembers = this.event.assignedStaffMembers.filter(x => x.id == member.id);
                        return of(false);
                      })
                    );
                  }
                  return forkJoin(this.courseRun.courseSessions.map(event => {
                    if (event.id !== this.event.id) {
                      const foundMember = event.assignedStaffMembers.find(staffMember => staffMember.id == staffID);
                      if (foundMember) {
                        return of(true);
                      }
                      event.assignedStaffMembers.push(member);
                    } else {
                      event = this.event;
                    }
                    toSend = {
                      id: event.id, assignedStaffMembers: event.assignedStaffMembers.map(staffMember => {
                        return {id: staffMember.id}
                      })
                    };
                    return this.apiSvc.updateCourseSession(toSend).pipe(
                      retry(3),
                      catchError(x => {
                        event.assignedStaffMembers = event.assignedStaffMembers.filter(x => x.id == member.id);
                        return of(false);
                      })
                    )
                  }))
                }),
                tap((result) => {
                  if (!result || ((result instanceof Array) && !result.reduce((acc, item) => {
                    return acc && item
                  }, true))) {
                    return this.toastySvc.error('Not all the assignations were processed correctly');
                  }
                  this.toastySvc.success('updated');
                }),
                catchError(error => {
                  return of(null);
                })
              )

            } else {

              return this.apiSvc.updateCourseSession(toSend)
                .pipe(
                  tap(() => {
                      this.toastySvc.success('updated');
                    }
                  ),
                  catchError(error => {
                    this.toastySvc.error('Assignation failed');
                    return of(null);
                  })
                );
            }
          }
          return of(null);
        }),
      ).subscribe()
    );
  
    this.subs.add(
      this.dragulaSvc.drop('modalResourceAllocation').pipe(
        switchMap(value=> {
          const {el, target, source} = value;

          if (!target) {
            return of(null);
          }

          if (target.className.indexOf('modalAssignedResource') > -1) {
            if (target == source) {
              //this is just a reorder or something..we don't care about this
              return of(null);
            }
            const resourceId = +el.getAttribute('data-resource-id');

            const toAssignResource = this.event.assignedResources.find(resource => resource.id == resourceId);

            let toSend = {
              id: this.event.id, assignedResources: this.event.assignedResources.map(resource => {
                return {id: resource.id}
              })
            };

            if (this.courseRun.courseSessions.length > 1) {

              const modal = this.modalSvc.show(ConfirmModalComponent);
              const component = <ConfirmModalComponent>modal.content;

              component.message = 'This courseRun has more than one session, do you want to assign this resource to all the sessions of this courseRun?';
              component.yesText = 'Yes';
              component.noText = 'No';


              return this.modalSvc.onHidden.pipe(
                take(1),
                switchMap(reason => {
                  if (reason !== ConfirmModalReason.YES) {
                    return this.apiSvc.updateCourseSession(toSend).pipe(
                      catchError(x => {
                        this.toastySvc.error('Failed to update allocation');
                        this.event.assignedResources = this.event.assignedResources.filter(x => x.id == toAssignResource.id);
                        return of(false);
                      })
                    );
                  }
                  return forkJoin(this.courseRun.courseSessions.map(event => {
                    if (event.id !== this.event.id) {
                      const foundResource = event.assignedResources.find(resource => resource.id == toAssignResource.id);
                      if (foundResource) {
                        return of(true);
                      }
                      event.assignedResources.push(toAssignResource);
                    } else {
                      event = this.event;
                    }
                    toSend = {
                      id: event.id, assignedResources: event.assignedResources.map(resource => {
                        return {id: resource.id}
                      })
                    };
                    return this.apiSvc.updateCourseSession(toSend).pipe(
                      retry(3),
                      catchError(x => {
                        event.assignedResources = event.assignedResources.filter(x => x.id == toAssignResource.id);
                        return of(false);
                      })
                    )
                  }))
                }),
                tap((result) => {
                  if (!result || ((result instanceof Array) && !result.reduce((acc, item) => {
                    return acc && item
                  }, true))) {
                    return this.toastySvc.error('Not all the assignations were processed correctly');
                  }
                  this.toastySvc.success('updated');
                }),
                catchError(error => {
                  return of(null);
                })
              )

            } else {

              return this.apiSvc.updateCourseSession(toSend)
                .pipe(
                  tap(() => {
                      this.toastySvc.success('updated');
                    }
                  ),
                  catchError(error => {
                    this.toastySvc.error('Assignation failed');
                    return of(null);
                  })
                );
            }
          }

          return of(null);
        })
      ).subscribe()
    );
  }
  private _event: CourseSession;
  courseRunChangePending: boolean;

  public get event(): CourseSession {
    return this._event;
  }

  public set event(event: CourseSession) {
    this.getCourseSessionData(event);
  };

  getCourseSessionData(event:CourseSession){
    this.loadingEvent = true;
    this.courseSession$ = this.apiSvc.getCourseSession(event.id)
    .pipe(
      switchMap(courseSession => {
        return forkJoin(
          this.apiSvc.getBookings({
            'no-page': true,
            jsonQuery: {
              'courseRun.id': courseSession.courseRun.id,
             // 'status': {$eq: 'confirmed'}
            }
          }).pipe(
            map(bookingsCollection => bookingsCollection.data),       
          ),
          this.apiSvc.getStaffs({
            jsonQuery: {
              'primaryCompany.id': courseSession.billingCompany.id,
              'workLocations.id': courseSession.courseRun.course.location.id,
              'canTeach': true
            },
            'no-page': true,
            order: 'contact.lastName'
          }).pipe(
            map(staffMembersCollection => staffMembersCollection.data),
          ),
          this.apiSvc.getResources({
            jsonQuery: {'location.id': courseSession.courseRun.course.location.id, enabled: true},
            'no-page': true,
            order: 'name'
          }).pipe(
            map(resourceCollection => resourceCollection.data),
          ),
          this.apiSvc.getCourseRun(courseSession.courseRun.id)
        ).pipe(
          map(([participantsData, staffMembers, resources, courseRun]) => {
            this.courseRun = courseRun;
            //-------we don't need to get staff from cantech list because maybe not found and will crash app
       
            this.staffMembers = staffMembers.filter(staffMember => !event.assignedStaffMembers.find(assignedStaffmember => assignedStaffmember.id == staffMember.id));
            this.resources = resources.filter(resource => !event.assignedResources.find(assignedResource => assignedResource.id == resource.id));
            this._event = courseSession;
            return courseSession;
          })
        )        
      }),
      tap((courseSession) => {
        this.loadingEvent = false;
      })
    );
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroy$.next();
    this.dragulaSvc.destroy('modalStaffAllocation');
    this.dragulaSvc.destroy('modalResourceAllocation');
  }

  ngOnInit() {
    this.pendingOrder$ = this.apiSvc.getStaffPendingBookingOrder();
    this.currentUser=this.authSvc.getCurrentUser();    
    this.canDeleteCourseRun=this.authSvc.checkHasPermission(Permissions.courseRunDelete,this.currentUser.staffMember.role); 
 
  }

  selectTab(tab) {
    this.tabSelected = tab;
    switch(tab){
      case this.tabsEnum.extraTools:
        this.$extraTools = this.apiBookingOrder.getBookingOrderExtras( this.event.id);
    }
  }

  removeAssignedMember(id: any) {
    if(this._isReadOnly)
      return;
    const toRemoveStaffmember = this.event.assignedStaffMembers.find(member => member.id == id);
    const newStaffMembers = this.event.assignedStaffMembers.slice().filter(member => member.id != id);

    const toSend = {
      id: this.event.id, assignedStaffMembers: newStaffMembers.map(staffMember => {
        return {id: staffMember.id}
      })
    };
    this.apiSvc.updateCourseSession(toSend).subscribe(() => {
      this.toastySvc.success('Updated');
      this.event.assignedStaffMembers = newStaffMembers;
      this.staffMembers.push(toRemoveStaffmember);
    });
  }


  bookCourseRun() {
    this.checkPendingChanges().subscribe((answer) => {
      if (answer) {
        this.bsModalRef.hide();
        this.router.navigate(['/pages/book/client'], {queryParams: {courseRun: this._event.courseRun.id}});
      }
    });
  }

  saveChanges() {
    //debugger;
    const changesToSave = [];
    if (this.sessionChangePending) {
      changesToSave.push(
        this.apiSvc.updateEvent(this.event)
          .pipe(
            tap(() => this.sessionChangePending = false)
          )
      );
    }

    if (this.courseRunChangePending) {
      changesToSave.push(
        this.apiSvc.updateCourseRun(this.courseRun)
          .pipe(
            switchMap(courseRun=>this.apiSvc.getCourseRun(courseRun.id)),
            tap((courseRun) => {
              this.courseRun = courseRun;
              this.courseRunChangePending = false
            })
          )
      )
    }

    forkJoin(changesToSave)
      .pipe(
        take(1)
      )
      .subscribe(() => {
          this.changesMade = true;
          this.toastySvc.success('Changes Saved!');
        }, error => {
          let message = 'Failed to save changes';
          if (error instanceof HttpErrorResponse) {
            message = `${message} - ${error.error.description}`
          }
          this.toastySvc.error(message);
        }
      )
  }

  goToCustomer(id) {
    this.checkPendingChanges().subscribe(canMoveForward => {
      if (canMoveForward) {
        this.bsModalRef.hide();
        this.router.navigate(['pages', 'customers', id]);
      }
    })

  }

  goToInvoice(id: number) {
    this.checkPendingChanges().subscribe(canMoveForward => {
      if (canMoveForward) {
        this.bsModalRef.hide();
        this.router.navigate(['pages', 'invoices', id]);
      }
    })

  }

  public checkPendingChanges(): Observable<boolean> {
    let decision = of(ConfirmModalReason.YES);

    if (this.sessionChangePending || this.courseRunChangePending) {
      const modal = this.modalSvc.show(ConfirmModalComponent);
      const component = <ConfirmModalComponent>modal.content;

      component.message = 'There are pending changes, are you sure you want to leave this page without saving?';

      decision = this.modalSvc.onHide.pipe(
        take(1)
      );
    }

    return decision.pipe(
      map((answer) => answer == ConfirmModalReason.YES)
    );
  }

  onSessionCreated(courseSession: CourseSession) {
    this.courseRunChangePending = true;
    const courseRun = new CourseRun().deserialize(this.courseRun);
    courseRun.courseSessions = [...this.courseRun.courseSessions, courseSession];
    this.courseRun = courseRun;
  }

  onSessionDeleted(courseSession: CourseSession) {
    this.courseRunChangePending = true;
    const courseRun = new CourseRun().deserialize(this.courseRun);
    courseRun.courseSessions = this.courseRun.courseSessions.filter(session => session.id !== courseSession.id);
    this.courseRun = courseRun;

  }

  onSessionModified(courseSession: CourseSession) {
    this.courseRunChangePending = true;
    const courseRun = new CourseRun().deserialize(this.courseRun);
    const filteredSessions = this.courseRun.courseSessions.filter(session => session.id !== courseSession.id);
    courseRun.courseSessions = [...filteredSessions, courseSession];
    this.courseRun = courseRun;
  }

  deleteCourseRun() {     
    if(confirm("This course run will be delete and all it's data, Are you sure?"))
    {
      this.modalSvc.setDismissReason(CourseEventModalHideReason.courseRunDeletion);
      this.bsModalRef.hide();
    }
  }

  timeZone(date, format, tz) {    
    return moment(date).tz(tz).format(format);
  }

  changeParticipantStatus($event, participant: BookingParticipant) {
    const modal = this.modalSvc.show(ParticipantStatusModalComponent);
    const component = <ParticipantStatusModalComponent>modal.content;
    component.participant = participant;

    this.modalSvc.onHidden.pipe(
      take(1),
      switchMap(dismissReason => {
        if (dismissReason === ParticipantStatusModalComponent.ACCEPT_REASON) {

          participant.status = $event;
          participant.notes = component.notes;

          return this.apiSvc.updateBookingParticipant(participant).pipe(
            map(success => {
              this.courseRunChangePending = true;
              this.toastySvc.success('Updated status');
              return true;
            }),
            catchError(error => {
              this.toastySvc.error('Failed to update status');
              return of(false);
            })
          );
        }
        return of(false);
      })
    ).subscribe(success => {
      if (!success) {
        const currentStatus = participant.status;
        participant.status = null;
        setTimeout(() => {
          participant.status = currentStatus;
        })
      }
    }, error1 => {
    })
  }

  removeAssignedResource(id: number | string) {
    if(this._isReadOnly)
      return;
    const toRemoveResource = this.event.assignedResources.find(resource => resource.id == id);
    const newResources = this.event.assignedResources.slice().filter(resource => resource.id != id);

    const toSend = {
      id: this.event.id, assignedResources: newResources.map(resource => {
        return {id: resource.id}
      })
    };
    this.apiSvc.updateCourseSession(toSend).subscribe(() => {
      this.toastySvc.success('Updated');
      this.event.assignedResources = newResources;
      this.resources.push(toRemoveResource);
    });
  }

  onAddPriceElement() {
    const modal = this.modalSvc.show(PriceElementModal);
    const component = <PriceElementModal>modal.content;
    component.billingCompany = this.courseRun.billingCompany;

    this.modalSvc.onHidden.pipe(take(1)).subscribe((reason) => {
      if (reason === 'savedChanges') {
        this.courseRun.priceElements.push(component.priceElement);
        this.courseRunChangePending = true;
      }
    })
  }

  getCourseRunPrice(courseRun: CourseRun) {
    const value = courseRun.priceElements.reduce(
      (prevValue, currentItem) => {
        const amount = new BigNumber(currentItem.amount);

        const taxPercentage = new BigNumber(currentItem.applyTax ? courseRun.billingCompany.taxRate : 0);

        return new BigNumber(prevValue.plus(amount.plus(taxPercentage.multipliedBy(amount).div(100))).toFixed(2))
      }, new BigNumber(0)
    );
    return value.toFixed(2);
  }

  onRemovePriceElement(priceElement: PriceElement) {
    this.courseRun.priceElements = this.courseRun.priceElements.filter(
      (priceElementItem) => {
        return priceElementItem.id !== priceElement.id
      }
    );
    this.courseRunChangePending = true;
  }
  loadWaivers(participants,course){

  }
  getWaivers(customerId,courseId){
    return this.apiWaiver.getCustomerCourseWaiver(customerId,courseId);
  }
  handleShowSignIn(participant){
    if(participant.course.requireSignIn && !participant.waiver.signToken)
    {
      alert("Client Must sign the course waiver before sign in.");
      return;
    }
    this.showSignIn = true;
    this.signInForm.patchValue(participant);
  }
  handleSignIn(participant){
    if(this.signInForm.valid)
      this.apiSvc.signInCourseRun(this.signInForm.value.courseRunBookingId,this.signInForm.value).subscribe(res=>{
        this.showSignIn=false;
        this.getCourseSessionData(this._event);
      })
  }
  handleShowSignOut(participant){
    this.showSignOut = true;    
    this.signOutForm.patchValue(participant);
  }
  handleSignOut(participant){
    if(this.signOutForm.valid)
    this.apiSvc.signOutCourseRun(this.signOutForm.value.courseRunBookingId,this.signOutForm.value).subscribe(res=>{
      this.showSignOut=false;    
      this.getCourseSessionData(this._event);
    })
  }
  reloadCourseRun(){
    this.getCourseSessionData(this._event);

  }
}

enum CourseEventModalTabs {
  info = 'info',
  participants = 'participants',
  book = 'book',
  courseRun = 'courseRun',
  nonParticipants = 'nonParticipants',
  staffAllocation = 'staffAllocation',
  resourceAllocation = 'resourceAllocation',
  priceElements = 'priceElements',
  extraTools ="extraTools"
}

export enum CourseEventModalHideReason {
  courseRunDeletion = '[CourseEventModal]deletion'
}

