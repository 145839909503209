import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Course } from 'models/Course';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  private baseUrl: string;
  constructor(private http: HttpClient ) {
    this.baseUrl = environment.apiUrl;  
  }
  
  getCoursesList(companyId:number): Observable<Course[]> {
    return this.http.get<Course[]>(`${this.baseUrl}/courses/list/${companyId}`);
  }
}
