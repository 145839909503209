import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  _display=true;
  colors=['#242424','#4e4e4e'];
  @Input() hasFooter:boolean=true;
  @Input() backgroundColorIndex:number=0;
  @Input()
  set display(value){
    this._display=value;
  }
  
  constructor() { }

  ngOnInit() {
  }

}
