import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value)
    {
      const dob=moment().diff(moment(value),'y');      
      return dob-10;
    }
    return null;
  }

}
