export const environment = {
  production: false,
  //apiUrl: 'https://dev-api.lagoon.co.uk',
  apiUrl: 'https://api.lagoon.co.uk',
  api2Url: 'https://api2.lagoon.co.uk/api',
  //api2Url: 'http://localhost:8088/api'

  //apiUrl: 'http://localhost:8083' 
 // apiUrl: 'http://3.20.65.178:8083/api/public'
};
