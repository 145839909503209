import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../_services/authentication/authentication.service';

@Injectable()
export class NoAuthGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //console.log('noAuth Called');
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/pages/dashboard']);
      return false;
    }
    return !this.authenticationService.isLoggedIn();
  }
}
