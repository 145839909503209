import { ExtraToolBookingTax } from "./ExtraToolBooking";

export default class ExtraToolPriceElement{
    id: string;
    name: string;
    price: number;  
    qty:number =0;  
    getTotalPrice(taxMode, taxRate) {
   
      let totalPrice=this.price;
        if (taxMode == ExtraToolBookingTax.IncludeTax)
          totalPrice= this.price + (this.price * taxRate) / 100;

        return (totalPrice * (this.qty || 1));
      }
    getUnitPrice(taxMode, taxRate) {
      let unitPrice=this.price;
        if (taxMode == ExtraToolBookingTax.IncludeTax)
          unitPrice= this.price + (this.price * taxRate) / 100;
        return unitPrice ;
      }
}