export enum Permissions {
  adminInvoices = "admin/invoices",
  adminReports = "admin/reports",
  attendance = "attendance",
  attendanceSignIn = "attendance/signIn",
  attendanceSignOut = "attendance/signOut",
  bookClient = "book/client",
  bookCompany = "book/company",
  bookPackage = "book/package",
  bookPartnerCompany = "book/partnerCompany",
  bookClientCompany = "bookClient/company",
  bookClientPartnerCompany = "bookClient/partnerCompany",
  bookings = "bookings",
  calendar = "calendar",
  calendarCourse = "calendar/course",
  calendarMy = "calendar/my",
  calendarResource = "calendar/resource",
  calendarStaff = "calendar/staff",
  contacts = "contacts",
  courseDelete = "course/delete",
  courseRunDelete = "courseRunDelete",
  courseGiftVouchers = "course/gift-vouchers",
  courseList = "course/list",
  courseMemberships = "course/memberships",
  courseRuns = "course/runs",
  courseCalendar = "courseCalendar",
  courses = "courses",
  customerCompanies = "customer-companies",
  customers = "customers",
  dashboard = "dashboard",
  discountCodes = "discount-codes",
  enquiries = "enquiries",
  invoices = "invoices",
  offers = "offers",
  orders = "orders",
  profile = "profile",
  reports = "reports",
  resourceCalendar = "resourceCalendar",
  settingsCancellationPolicies = "settings/cancellationPolicies",
  settingsCompanies = "settings/companies",
  settingsCourseRuns = "settings/course-runs",
  settingsCustomerCompanies = "settings/customer-companies",
  settingsDiscountCodes = "settings/discount-codes",
  settingsEmailTemplates = "settings/email-templates",
  settingsGiftVoucherImages = "settings/gift-voucher-images",
  settingsLocations = "settings/locations",
  settingsMembershipTypes = "settings/membershipTypes",
  settingsMemberships = "settings/memberships",
  settingsOffers = "settings/offers",
  settingsPackages = "settings/packages",
  settingsPermissions = "settings/permissions",
  settingsResourceTypes = "settings/resource-types",
  settingsResources = "settings/resources",
  settingsSalesCategories = "settings/salesCategories",
  settingsExtraTools = "settings/extra-tools",
  staff = "staff",
  staffMembers = "staff-members",
  staffMembersAvailabilty = "staff-members/availabilty",
  staffMembersStatus = "staff-members/status",
  staffMembersTimeline = "staff-members/timeline",
  staffAllocation = "staff/allocation",
  staffAvailability = "staff/availability",
  staffAvailabilty = "staff/availabilty",
  staffStatus = "staff/status",
  staffTimeline = "staff/timeline",
  staffCalendar = "staffCalendar",
  userCalendar = "user-calendar",
}
