import {Injectable} from '@angular/core';
import {Route, Router, Routes} from '@angular/router';

import {ApiService} from '../../../_services/api.service';
import {combineLatest} from 'rxjs/operators';
import {AuthenticationService} from '../../../_services';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class BaMenuService {
  _routes: Route[];
  menuItems = new BehaviorSubject<any[]>([]);

  protected _currentMenuItem = {};

  constructor(private _router: Router, private apiSvc: ApiService, private profileSvc: AuthenticationService) {

  }

  public updateMenus() {
    this.updateMenuByRoutes(this._routes);
  }

  /**
   * Updates the routes in the theme
   *
   * @param {Routes} routes Type compatible with app.theme.ts
   */
  public updateMenuByRoutes(routes: Routes) {
    //console.log("routes===>",routes);
    this._routes = routes;
    this.apiSvc.loadPermissions().pipe(
      combineLatest(
        this.profileSvc.getProfileData()
      )
    ).subscribe(([permissions, profile]) => {
      const convertedRoutes = this.convertRoutesToMenus(JSON.parse(JSON.stringify(routes)), permissions, profile);
      this.menuItems.next(convertedRoutes);
    })

  }

  public convertRoutesToMenus(routes: Routes, permissions, profile): any[] {
    if(!profile.staffMember){
      return [];
    }
    //console.log('permission',permissions,routes);
    const role = profile.staffMember.role;
    
/*
    const pagesRoute = routes.find(x => x.path == 'pages');
    if (role != 'super_admin') {
      if (pagesRoute) {
        pagesRoute.children = pagesRoute.children.filter(x => {
          if (x.children && x.children.length > 0) {
            //we are looking at a path + subpath..
            x.children = x.children.filter(y => {
              return (permissions[`${x.path}/${y.path}`] && permissions[`${x.path}/${y.path}`][role]) ||( permissions[`${x.path}`] && permissions[`${x.path}`][role])
            });

            return x.children.length > 0;
          }
          return permissions[x.path] && permissions[x.path][role];
        })
      }
    }
    */
    const items = this._convertArrayToItems(routes);
    return this._skipEmpty(items);
  }

  public getCurrentItem(): any {
    return this._currentMenuItem;
  }

  public selectMenuItem(menuItems: any[]): any[] {
    //console.log('item select',menuItems);
    const items = [];
    menuItems.forEach((item) => {
      this._selectItem(item);

      if (item.selected) {
        this._currentMenuItem = item;
      }
      if (item.children && item.children.length > 0) {
        item.children = this.selectMenuItem(item.children);
      }
      items.push(item);
    });
    return items;
  }

  protected _skipEmpty(items: any[]): any[] {
    const menu = [];
    items.forEach((item) => {
      let menuItem;
      if (item.skip) {
        if (item.children && item.children.length > 0) {
          menuItem = item.children;
        }
      } else {
        menuItem = item;
      }

      if (menuItem) {
        menu.push(menuItem);
      }
    });

    return [].concat.apply([], menu);
  }

  protected _convertArrayToItems(routes: any[], parent?: any): any[] {
    const items = [];
    routes.forEach((route) => {
      items.push(this._convertObjectToItem(route, parent));
    });
    return items;
  }

  protected _convertObjectToItem(object, parent?: any): any {
    let item: any = {};
    if (object.data && object.data.menu) {
      // this is a theme object
      item = object.data.menu;
      item.route = object;
      delete item.route.data.menu;
    } else {
      item.route = object;
      item.skip = true;
    }

    // we have to collect all paths to correctly build the url then
    if (Array.isArray(item.route.path)) {
      item.route.paths = item.route.path;
    } else {
      item.route.paths = parent && parent.route && parent.route.paths ? parent.route.paths.slice(0) : ['/'];
      if (!!item.route.path) item.route.paths.push(item.route.path);
    }

    if (object.children && object.children.length > 0) {
      item.children = this._convertArrayToItems(object.children, item);
    }

    const prepared = this._prepareItem(item);

    // if current item is selected or expanded - then parent is expanded too
    if ((prepared.selected || prepared.expanded) && parent) {
      parent.expanded = true;
    }

    return prepared;
  }

  protected _prepareItem(object: any): any {
    if (!object.skip) {
      object.target = object.target || '';
      object.pathMatch = object.pathMatch || 'full';
      return this._selectItem(object);
    }

    return object;
  }

  protected _selectItem(object: any): any {
    //console.log('item select',object);

    object.selected = this._router.isActive(this._router.createUrlTree(object.route.paths), object.pathMatch === 'full');
    return object;
  }
}
