import { Component, Input, OnInit, Optional, Self } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";

@Component({
  selector: "Toggle",
  templateUrl: "./toggle.component.html",
  styleUrls: ["./toggle.component.scss"],
})
export class ToggleComponent implements ControlValueAccessor {
  _value: boolean;
  _disabled: boolean;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() name: string;

  constructor(@Self() @Optional() public control: NgControl) {
    this.control.valueAccessor = this;
  }
  onChange = (_: any) => {};
  onTouched = (_: any) => {};
  writeValue(obj: boolean): void {
    this._value = obj||false;
    this.onChange(this._value );
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  ngOnInit() {}
  handleOnChange(event) {
    this.onTouched(true);
    this.onChange(this._value);
   }
}
