import {Contact} from './Contact';
import {BookingOrder} from './BookingOrder';
import {Company} from './Company';
import {GiftVoucherTransaction} from './GiftVoucherTransaction';
import {Serializable} from './Serializable';
import {GiftVoucherImage} from './GiftVoucherImage';

export class GiftVoucher implements Serializable<GiftVoucher> {
  id?: string | number;
  amount?: string;
  assignedTo?: Contact = null;
  requestedBy?: Contact = null;
  validityPeriod?: number;
  activatedAt?: string;
  createdBy?: Contact;
  createdAt?: string;
  bookingOrder?: BookingOrder;
  company?: Company;
  transactions?: GiftVoucherTransaction[];
  notes?: string;
  expiryDate?: string;
  emailTo?: string;
  image_id:number;
  image?: GiftVoucherImage;
  dateToSend?: string;
  timeToSend?: string;
  redeemedAt?:string;
  active?:boolean;
  last_updated_by?:number;
  message?:string;
  assignedTo_id?:number;
  company_id?: number;
  deserialize(input) {
    if (!input) {
      return null;
    }

    this.id = input.id;
    this.amount = input.amount;
    this.assignedTo = new Contact().deserialize(input.assignedTo);
    this.requestedBy = new Contact().deserialize(input.requestedBy);
    this.validityPeriod = input.validityPeriod;
    this.activatedAt = input.activatedAt;
    this.createdBy = new Contact().deserialize(input.createdBy);
    this.createdAt = input.createdAt;
    this.bookingOrder = new BookingOrder().deserialize(input.bookingOrder);
    this.company = input.company;
    this.emailTo = input.emailTo;
    this.dateToSend = input.dateToSend;

    if (input.transactions) {
      this.transactions = input.transactions.map(item => {
        const transaction = new GiftVoucherTransaction().deserialize(item);
        transaction.giftVoucher = this;
        return transaction;
      });
    }


    return this;
  }
}
