import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CourseRun} from '../../../../models/CourseRun';
import {ResourceCalendar} from '../resourceCalendar/ResourceCalendar';
import * as moment from 'moment';
import {Moment} from 'moment';
import {CourseSession} from '../../../../models/CourseSession';
import {ToastyService} from 'ng2-toasty';
import {BsModalService} from 'ngx-bootstrap';
import {ConfirmModalComponent, ConfirmModalReason} from '../confirmModal/confirm-modal.component';
import {take, takeUntil, tap} from 'rxjs/operators';
import {SystemCompanyService} from '../../../_services/system-company.service';
import {Company} from '../../../../models/Company';
import {ToastrService} from 'ngx-toastr';
import 'moment-timezone';
import {Subject} from 'rxjs';

@Component({
  selector: 'pavweb-course-run-calendar',
  templateUrl: './course-run-calendar.component.html',
  styleUrls: ['./course-run-calendar.component.scss']
})
export class CourseRunCalendarComponent implements OnInit, OnDestroy {

  _courseRun: CourseRun;
  private _onDestroy: Subject<any> = new Subject<any>();
  private currentCompany: Company;
  _isReadOnly = false;
  @Input() set isReadOnly(value:boolean){
    this.calendarOptions.editable=!value;
    this.calendarOptions.droppable=!value;
    this._isReadOnly = value;
  }

  @Input() set courseRun(courseRun: CourseRun) {

    const previousCourseRun = this._courseRun;
    this._courseRun = courseRun;
    if (!this._courseRun) {
      return;
    }

    if (this.calendarRef) {
      this.calendarRef.fullCalendar('option', 'timezone', this._courseRun.billingCompany.timezone);

      if (!previousCourseRun || previousCourseRun && courseRun && previousCourseRun.id !== courseRun.id) {
   
        this.calendarRef.fullCalendar('gotoDate', moment(this._courseRun.startDate));
      }
      this.calendarRef.fullCalendar('refetchEvents');
    }
  };

  calendarRef;
  calendarOptions;


  @Output() sessionDeleted = new EventEmitter();
  @Output() sessionModified = new EventEmitter();
  @Output() sessionCreated = new EventEmitter();

  constructor(private toastySvc: ToastrService, private modalSvc: BsModalService, private companySvc: SystemCompanyService) {
    this.calendarOptions = {
      height: '1100px',
      scrollTime: '08:30:00',
      defaultView: 'month',
      defaultDate: moment().day(-6).toDate(),
      columnFormat: 'dddd',
      nowIndicator: false,
      eventClick: this.eventClick.bind(this),
      firstDay: 1,
      header: {
        left: 'month,agendaWeek',
        center: 'title',
        right: 'today prev,next'
      },
      allDaySlot: false,    
      eventLimit: true, // allow "more" link when too many events
      selectable: true,
      selectHelper: true,
      overlap: false,
      eventOverlap: false,
      selectOverlap: false,
      select: this.eventSelect.bind(this),
      events: this.fetchEvents.bind(this),
      eventDrop: this.eventModify.bind(this),
      eventResize: this.eventModify.bind(this),
      editable:  !this.isReadOnly,
      droppable: !this.isReadOnly,
    };
   
    this.companySvc.getCompany().pipe(
      takeUntil(this._onDestroy),
      tap(company => this.currentCompany = company)
    )
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this._onDestroy.next();
  }

  private eventClick(event): any {
    if(this._isReadOnly)
    return false;
    if (this._courseRun.course.generator == 'slots') {
      this.toastySvc.warning('A slotted course courseRun must have 1 session, cannot delete it');
      return;
    }

    const modal = this.modalSvc.show(ConfirmModalComponent);
    const component = <ConfirmModalComponent>modal.content;

    component.message = 'Are you sure you want to delete this date?';

    this.modalSvc.onHidden.pipe(
      take(1)
    ).subscribe(reason => {
      if (reason === ConfirmModalReason.YES) {
        this.sessionDeleted.next(this._courseRun.courseSessions.find(session => session.id == event.id));
      }
    })

  };

  private eventSelect(start: Moment, end: Moment): any {
    if(this._isReadOnly)
    return false;
    if (!this._courseRun) {
      if (this.calendarRef) {
        this.calendarRef.fullCalendar('unselect');
      }
      return;
    }
    if (this._courseRun.course.generator == 'slots') {
      this.toastySvc.warning('Cannot create more than one event per course run in a slotted course');
      this.calendarRef.fullCalendar('unselect');
      return;
    }

    if (start.diff(moment()) < 0) {
      this.toastySvc.warning('Cannot create sessions in the past');
      this.calendarRef.fullCalendar('unselect');
      return;
    }

    const newSession = new CourseSession();
    newSession.startDate = moment.tz(start.format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', this._courseRun.billingCompany.timezone).utc().format();
    newSession.endDate = moment.tz(end.format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', this._courseRun.billingCompany.timezone).utc().format();
    newSession.courseRun = this._courseRun;

    if (this.calendarRef) {
      this.calendarRef.fullCalendar('unselect');
    }

    this.sessionCreated.next(newSession);
  };

  private fetchEvents(startDate, endDate, tz, callback): any {

    if (!this._courseRun || !this._courseRun.courseSessions) {
      return callback([]);
    }

    const events = this._courseRun.courseSessions.map((session) => {
      return {
        title: this._courseRun.course.name,
        start: moment.utc(session.startDate).tz(this._courseRun.billingCompany.timezone),
        end: moment.utc(session.endDate).tz(this._courseRun.billingCompany.timezone),
        id: session.id
      }
    });

    callback(events);
  };

  private eventModify(event, delta, revertFunc, jsEvent, ui, view) {
    const foundSession = this._courseRun.courseSessions.find(session => session.id == event.id);
    if (moment(foundSession.startDate).diff(moment()) < 0) {
      this.toastySvc.warning('Cannot modify sessions in the past');
      return revertFunc();
    }

    if (event.start.diff(moment()) < 0) {
      this.toastySvc.warning('Cannot move sessions to the past');
      return revertFunc();
    }

    //the given format is in company Timezone time
    foundSession.startDate = moment.tz(event.start.format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', this._courseRun.billingCompany.timezone).utc().format();
    foundSession.endDate = moment.tz(event.end.format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', this._courseRun.billingCompany.timezone).utc().format();
    this.sessionModified.next(foundSession);
  }

  calendarInit(resourceCalendar: ResourceCalendar) {
    this.calendarRef = resourceCalendar;
    if (this.calendarRef) {      
      this.calendarRef.fullCalendar('gotoDate', moment(this._courseRun.startDate));
    }
  }
}
