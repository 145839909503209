import { Component, OnInit, Input, Self, Optional } from '@angular/core';

import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TemplateVariables } from 'app/_constants/TemplateVariables';
 
declare const CKEDITOR: any;

@Component({
  selector: 'ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss'],
  providers: [
  
  ]
})
export class CKEditorComponent implements ControlValueAccessor {
  elementName;
  templatVairalbes: Array<any>;
  @Input() height:number;
  @Input() 
  public set tags(value:string[]){    
    this.templatVairalbes=value;
  }
  private _isDisabled: boolean;
   
  constructor(@Self() @Optional() public control: NgControl) {
    this.control.valueAccessor = this;
    this.elementName = "editor1";
    this.templatVairalbes = this.tags;
  }
  onChange = (_: any) => { };
  onTouched = (_: any) => { };
  writeValue(value: any): void {    
    if(document.getElementById( this.elementName ))
    document.getElementById( this.elementName ).innerHTML = value;
    this.onChange(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  ngOnInit() {
    setTimeout(() => {
      this.initEditor();
    }
      , 200);

  }
  initEditor() {
    CKEDITOR.disableAutoInline = true;
    if (!CKEDITOR.plugins.registered.hcard)
      CKEDITOR.plugins.add('hcard', {
        requires: 'widget',

        init: function (editor) {
          editor.widgets.add('hcard', {
            allowedContent: 'span(!h-card);',
            requiredContent: 'span(h-card)',
            pathName: 'hcard',

            upcast: function (el) {
              return el.name == 'span' && el.hasClass('h-card');
            }
          });
          editor.addFeature(editor.widgets.registered.hcard); 
        }
      });
    const _this=this;
    CKEDITOR.on('instanceReady', function (e) {
      $(e.editor.element.$).removeAttr("title");
      e.editor.on('change', function (event) {      
        _this.onTouched(true);
        _this.onChange( e.editor.getData());
      });
      CKEDITOR.document.getById('contactList').on('dragstart', function (evt) {        
        var target = evt.data.getTarget().getAscendant('div', true);
        CKEDITOR.plugins.clipboard.initDragDataTransfer(evt);
        var dataTransfer = evt.data.dataTransfer;
        console.log('index', target.data('id'),evt)
        const temp = TemplateVariables.find(t => t.id === target.data('id'))[0];
        dataTransfer.setData('contact', temp);
        dataTransfer.setData('text/html', "<span class='h-card'>" + target.data('code') + "</span>");
      });
    });
    const removeButtons = 'About,Maximize,ShowBlocks,Flash,SpecialChar,Iframe,Language,BidiRtl,BidiLtr,CreateDiv,Indent,Outdent,RemoveFormat,CopyFormatting,Subscript,Superscript,HiddenField,ImageButton,Button,Select,Textarea,TextField,Radio,Checkbox,Form,SelectAll,Find,Replace,Templates,Preview,NewPage,Save';

    const toolbarGroups = [      
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup','colorbutton'  ] },
      { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
      '/',
      { name: 'links', groups: [ 'links' ] },
      { name: 'insert', groups: [ 'insert' ] },
      { name: 'styles', groups: [ 'styles'] },      
      { name: 'colors', groups: [ 'colors' ] },
      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
      { name: 'forms', groups: [ 'forms' ] },      
      '/',
      { name: 'tools', groups: [ 'tools' ] },
      { name: 'others', groups: [ 'others' ] },
      { name: 'about', groups: [ 'about' ] }
      ];
     
    // Initialize the editor with the hcard plugin.
    CKEDITOR.inline(this.elementName, {
      extraPlugins: 'hcard,sourcedialog,justify,colorbutton',
      toolbarGroups,
      removeButtons
    });
  }

}
