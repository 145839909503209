import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ExtraToolViewModel } from 'app/viewModels';
import { ExtraToolsService } from 'app/_services';
import { ApiService } from 'app/_services/api.service';
import { SystemCompanyService } from 'app/_services/system-company.service';
import { Company, ExtraTool, ExtraToolBooking, ExtraToolPriceElement } from 'models';
import { DiscountType } from 'models/DiscountCode';
import ExtraToolBookingPriceElement from 'models/ExtraToolBookingPriceElement';
import { ToastrService } from 'ngx-toastr';
 
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: "app-extra-tools-modal",
  templateUrl: "./extra-tools-modal.component.html",
  styleUrls: ["./extra-tools-modal.component.scss"],
})
export class ExtraToolsModalComponent implements OnInit {
  displayModal = false;
  $extraTools: Observable<ExtraToolViewModel[]>;
  apiUrl: string;
  company: Company;
  @Input() courseId: number;
  @Input() bookingOrderId: number;
  @Output() onClose: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  constructor(
    private api: ExtraToolsService,
    private fb: FormBuilder,
    private companySvc: SystemCompanyService,
    private apiSvc: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.apiUrl = this.apiSvc.getApiUrl();
    console.log(this.courseId, this.bookingOrderId);
  }
  async handleAdd(tools) {
    console.log("add", tools);
    try {
      const hasInvalid = tools.filter((t) => {
        return (
          t.priceElements.filter((p) => t.maxQty > 0 && p.qty > t.maxQty)
            .length > 0
        );
      });
      if (hasInvalid.length > 0) {
        this.toastr.error("Extra Qty must be not greater than allowed value");
        return;
      }
      const validExtra = tools.filter((t) => {
        return t.priceElements.filter((p) => p.qty > 0).length > 0;
      });

      if (validExtra.length == 0) {
        this.toastr.error("Please select at least one extra");
        return;
      }
      if (!this.courseId || !this.bookingOrderId) {
        this.toastr.error("Please select booking order and course");
        return;
      }

      for (let i = 0; i < validExtra.length; i++) {
        const extra = validExtra[i];
        const structure = {
          extraTool: {
            id: extra.id,
            discountCode: extra.discountCode,
          },
          priceElements: extra.priceElements,
          taxMode: extra.taxMode,
        };
        console.log({ validExtra, structure });
        await this.api
          .addToolToPendingOrder(this.bookingOrderId, structure)
          .toPromise();
      }
      this.onClose.next(true);
    } catch (ex) {
      this.onClose.next(false);
    }
  }
  handleDisplayModal() {
    this.displayModal = true;
    this.$extraTools = this.companySvc.getCompany().pipe(
      switchMap((company: Company) => {
        this.company = company;
        return this.api.getAllExtraTools(company.id, true)
        .pipe(
          map(tools=>tools.filter(tool=>tool.availableAllCourses || tool.courses.find(c=>c.id == this.courseId)))
        );
      }),
      map((extras) => {
        return extras.map((extra) => {
          extra.maxQty = this.getMaxQuantity(extra);
          return extra;
        });
      }),
      tap((res) => console.log({ res }))
    );
  }

  getMaxQuantity(extra: ExtraToolViewModel) {
    let course;
    if (extra.courses)
      course = extra.courses.find((c) => c.id == this.courseId);
    if (course) return course.maxQty;
    return "";
  }

  applyDiscount(
    extra: ExtraToolViewModel,
    discountCodeInput: HTMLInputElement
  ) {
    this.api
      .checkDiscountAgainstExtra(extra, discountCodeInput.value)
      .subscribe(
        (discountCode) => {
          extra.discountCode = discountCode;
        },
        (error) => {
          this.toastr.error("Discount Code not valid");
          discountCodeInput.value = null;
        }
      );
  }
  
  getDiscountValue(extra: ExtraToolViewModel,priceElement: ExtraToolPriceElement) {
    if (!extra.discountCode) return 0;
    const totalValue = priceElement.getTotalPrice(extra.taxMode, this.company.taxRate);
    if (extra.discountCode.type == DiscountType.FIXED_AMOUNT)
      return parseFloat(extra.discountCode.amount);
    if (extra.discountCode.type == DiscountType.PERCENTAGE)
      return (parseFloat(extra.discountCode.amount) / 100) * totalValue;
    return 0;
  }

  
}
