import { ExtraTool, ExtraToolPriceElement, Course, Company } from "models";
import { DiscountCode, DiscountType } from "models/DiscountCode";
import round from "../utils/round";
export default class ExtraToolViewModel extends ExtraTool {
  company?: Company;
  priceElements?: ExtraToolPriceElement[];
  courses?: { id: number; name: string; maxQty: number }[];
  maxQty?: number;
  discountCode?: DiscountCode;
  getTotalPrice() {
    let total =0;
    if (this.priceElements.length > 0){
      total = this.priceElements
        .filter(p=>p.qty>0)
        .map((p) => p.getTotalPrice(this.taxMode, this.company.taxRate))
        .reduce((a, b) => a + b, 0);
        if(this.discountCode)
        {            
             if (this.discountCode.type == DiscountType.FIXED_AMOUNT)
               total -=parseFloat(this.discountCode.amount);
            if (this.discountCode.type == DiscountType.PERCENTAGE)
              total -= (parseFloat(this.discountCode.amount) / 100) * total;
        }
    }
    
    return round(total);
  }
}
