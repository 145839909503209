import {Company} from './Company';
import {UUID} from '../app/utils/Utils';
import {Course} from './Course';
import {Serializable} from './Serializable';
import ExtraTool from './ExtraTool';

export enum DiscountType {
  PERCENTAGE = 'percentage',
  FIXED_AMOUNT = 'fixed_amount'
}

export class DiscountCode implements Serializable<DiscountCode> {
  id: string | number;
  code: string;
  type?: DiscountType;
  amount?: string;
  courses?: Course[];
  company?: Company;
  expiryDate?: string;
  extraTools?: ExtraTool[];

  constructor() {
    this.id = UUID();
    this.courses = [];
    this.company = new Company();
    this.type = DiscountType.FIXED_AMOUNT;
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.code = input.code;
    this.type = input.type;
    this.amount = input.amount;
    if (input.courses) {
      this.courses = input.courses.map(item => {
        return new Course().deserialize(item);
      });
    }
    if (input.extraTools) {
      this.extraTools = input.extraTools.map(item => {
        return new ExtraTool().deserialize(item);
      });
    }

    this.company = new Company().deserialize(input.company);
    this.expiryDate = input.expiryDate;
  }
}
