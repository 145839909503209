import {RouterModule, Routes} from '@angular/router';
import {Pages} from './pages.component';
import {ModuleWithProviders} from '@angular/core';
import {AuthGuard} from '../_guards/auth/auth.guard';
import {NoAuthGuard} from '../_guards/no-auth/no-auth.guard';
import {PermissionsGuard} from '../_guards/permissions/permissions-guard';
// noinspection TypeScriptValidateTypes

// export function loadChildren(path) { return System.import(path); };

export const pagesRoutes: Routes = [
  {
    path: 'login',
    loadChildren: 'app/pages/login/login.module#LoginModule',
    canActivate: [NoAuthGuard]
  },
  {
    path: 'resetPassword',
    loadChildren: 'app/pages/reset-password/reset-password.module#ResetPasswordModule'
  },
  {
    path: 'forgotPassword',
    loadChildren: 'app/pages/forgot-password/forgot-password.module#ForgotPasswordModule'
  },
  {
    path: 'development',
    loadChildren: 'app/pages/development/development.module#DevelopmentModule',
  },
  {
    path: 'redeemVoucher',
    loadChildren: 'app/pages/redeem-voucher/redeem-voucher.module#RedeemVoucherModule',
  },
  {
    path: 'pages',
    component: Pages,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full',},
      {path: 'profile', loadChildren: 'app/pages/profile/profile.page.module#ProfilePageModule'},
      {
        path: 'dashboard',
        loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'customers',
        loadChildren: 'app/pages/customers/customers.module#CustomersModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'contacts',
        loadChildren: 'app/pages/contacts/contacts.module#ContactsModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'staff/allocation',
        loadChildren: 'app/pages/staff-allocation/staff-allocation.module#StaffAllocationModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'staff/timeline',
        loadChildren: 'app/pages/staff-members/staff-timeline/staff-timeline.module#StaffTimelineModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'staff/status',
        loadChildren: 'app/pages/staff-members/staff-status/staff-status.module#StaffStatusModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'staff/availability',
        loadChildren: 'app/pages/staff-members/staff-availability/staff-availability.module#StaffAvailabilityModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'staff',
        loadChildren: 'app/pages/staff-members/staff-members.module#StaffMembersModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'course/list',
        loadChildren: 'app/pages/course/course.module#CourseModule',
        canActivate: [PermissionsGuard],
      },

      {
        path: 'calendar/staff',
        loadChildren: 'app/pages/staff-calendar/staff-calendar.module#StaffCalendarModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'calendar/resource',
        loadChildren: 'app/pages/resource-calendar/resource-calendar.module#ResourceCalendarModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'calendar/course',
        loadChildren: 'app/pages/courseCalendar/course-calendar.module#CourseCalendarModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'bookings',
        loadChildren: 'app/pages/bookings/bookings.page.module#BookingsPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'book/client',
        loadChildren: 'app/pages/clientBook/clientBook.page.module#ClientBookPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'book/company',
        loadChildren: 'app/pages/company-book/company-book.module#CompanyBookModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'book/partnerCompany',
        loadChildren: 'app/pages/company-book/company-book.module#CompanyBookModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'book/package',
        loadChildren: 'app/pages/package-book/package-book.module#PackageBookModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'enquiries',
        loadChildren: 'app/pages/enquiries/enquiries.page.module#EnquiriesPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/customer-companies',
        loadChildren: 'app/pages/customer-companies/customer-companies.module#CustomerCompaniesModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'waitingListRequests',
        loadChildren: 'app/pages/waitingListRequests/waiting-list-requests.page.module#WaitingListRequestsPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'orders',
        loadChildren: 'app/pages/orders/orders.module#OrdersModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'admin/invoices',
        loadChildren: 'app/pages/invoices/invoices.module#InvoicesModule',
        canActivate: [PermissionsGuard],
      },

      {
        path: 'course/gift-vouchers',
        loadChildren: 'app/pages/gift-vouchers/gift-vouchers.module#GiftVouchersModule',
        canActivate: [PermissionsGuard],
      },

      {
        path: 'admin/reports',
        loadChildren: 'app/pages/reports/reports.module#ReportsModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/resources',
        loadChildren: 'app/pages/resources/resources.page.module#ResourcesPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/resource-types' +
          '', loadChildren: 'app/pages/resource-types/resource-types.page.module#ResourceTypesPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/locations',
        loadChildren: 'app/pages/locations/locations.page.module#LocationsPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/companies',
        loadChildren: 'app/pages/companies/companies.page.module#CompaniesPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/cancellationPolicies',
        loadChildren: 'app/pages/cancellation-policies/cancellation-policies.page.module#CancellationPoliciesPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'course/runs',
        loadChildren: 'app/pages/course-runs/course-runs.module#CourseRunsModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/membershipTypes',
        loadChildren: 'app/pages/membership-types/membership-types.page.module#MembershipTypesPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'course/memberships',
        loadChildren: 'app/pages/memberships/memberships.page.module#MembershipsPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/salesCategories',
        loadChildren: 'app/pages/sales-categories/sales-categories.page.module#SalesCategoriesPageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/permissions',
        loadChildren: 'app/pages/permissions/permissions.module#PermissionsModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/offers',
        loadChildren: 'app/pages/offers/offers.module#OffersModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/discount-codes',
        loadChildren: 'app/pages/discount-codes/discount-codes.module#DiscountCodesModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/email-templates',
        loadChildren: 'app/pages/email-templates/email-templates.module#EmailTemplatesModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/gift-voucher-images',
        loadChildren: 'app/pages/gift-vouchers-images/gift-voucher-images.module#GiftVoucherImagesModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/packages',
        loadChildren: 'app/pages/package/package.module#PackageModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'settings/extra-tools',
        loadChildren: 'app/pages/extra-tools/extra-tools.module#ExtraToolsModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'calendar/my',
        loadChildren: 'app/pages/user-calendar/user-calendar.module#UserCalendarModule',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'attendance',
        loadChildren: 'app/pages/attendance/attendance.module#AttendanceModule',
        canActivate: [PermissionsGuard],
      },      
      {path: '**', redirectTo: 'dashboard'}
    ]
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(pagesRoutes);
