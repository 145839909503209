import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import BookingParticipantViewModel from 'app/viewModels/BookingParticipantViewModel';
import { CourseRunBookingViewModel } from 'app/viewModels/CourseRunBookingViewModel';
import { environment } from 'environments/environment';
import { BookingParticipant } from 'models/BookingParticipant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiCourseRunService {

  private baseUrl2: string;
  constructor(private http: HttpClient ) {
     this.baseUrl2 =   environment.api2Url+"/course-run";
  }

  getCourseRunBookings(courseRunId:number|string):Observable<CourseRunBookingViewModel[]>{
    return this.http.get<CourseRunBookingViewModel[]>(`${this.baseUrl2}/${courseRunId}/bookings`);
  }

  getCourseRunParticipants(courseRunId:number|string):Observable<BookingParticipantViewModel[]>{
    return this.http.get<BookingParticipantViewModel[]>(`${this.baseUrl2}/${courseRunId}/participants`);
  }
}
