import { BookingOrder } from "./BookingOrder";
import ExtraTool from "./ExtraTool";
import { ExtraToolBookingTax } from "./ExtraToolBooking";

export default class ExtraToolBookingPriceElement{
    id:number;
    name: string;
    price: number ;
    qty: number;
    getTotal(taxMode,taxRate){
      let total = this.qty * this.price;
      if(taxMode != ExtraToolBookingTax.NoTax   )
        total += total*taxRate/100;
      return total;
    }
    
}
 