import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ApiService} from 'app/_services/api.service';
import {concatMap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {StaffStatusColor} from 'app/_constants/staff-status';

@Component({
  selector: 'staff-availability-details',
  templateUrl: './availability-details.component.html',
  styleUrls: ['./availability-details.component.scss']
})
export class AvailabilityDetailsComponent implements OnInit {
  @Input() isReadOnly: boolean;
  @Input() modalRef: BsModalRef;
  @Input() newSlot = true;
  @Input() timezone: string;
  @Input() availability = null;
  @Output() onClose = new EventEmitter();
  availabilities = [];
  formData: FormGroup;
  statusColor: StaffStatusColor;

  constructor(private fb: FormBuilder,
              private apiService: ApiService,
              private toaster: ToastrService,
  ) {
    
  }

  ngOnInit() {
    this.formData = this.fb.group({
      startDate: [{value:null, disabled:this.isReadOnly}, Validators.required],
      startTime: [{value:null, disabled:this.isReadOnly}, Validators.required],
      endDate: [{value:null, disabled:this.isReadOnly}, Validators.required],
      endTime: [{value:null, disabled:this.isReadOnly}, Validators.required],
      staffMember_id: [{value:null, disabled:this.isReadOnly}, Validators.required],
      location_id: [1, Validators.required],
      lunchStartTime: [moment('13:00').toDate()],
      lunchEndTime: [moment('13:30').toDate()],
      status: [{value:1, disabled:this.isReadOnly},Validators.required],
      description: [{value:null, disabled:this.isReadOnly}],
      id: [null]
    });
    this.statusColor = new StaffStatusColor();
    this.availabilities = this.statusColor.availability.filter(status => status.id != 10);
    if (this.availability != null) {
      this.availability.startTime = this.getBrowserDate(this.availability.startDate);
      this.availability.endTime = this.getBrowserDate(this.availability.endDate);
      this.availability.lunchStartTime = this.getBrowserDate(this.availability.lunchStartTime);
      this.availability.lunchEndTime = this.getBrowserDate(this.availability.lunchEndTime);
      this.formData.patchValue(this.availability);
      
    }
  }

  getBrowserDate(val) {
    if (val != null && val != '') {
      var m = moment(val);
      var year = m.year();
      var month = m.month();
      var day = m.date();
      var hour = m.hour();
      var minute = m.minute();
      return new Date(year, month, day, hour, minute);
    } else return null;
  }

  save() {

    let tstart = moment.tz(moment(this.formData.value.startDate).format('YYYY-MM-DD') + ' ' + moment(this.formData.value.startTime).format('HH:mm:ss'), this.timezone);
    let tend = moment.tz(moment(this.formData.value.endDate).format('YYYY-MM-DD') + ' ' + moment(this.formData.value.endTime).format('HH:mm:ss'), this.timezone);
    let lstart = moment.tz(moment(this.formData.value.startDate).format('YYYY-MM-DD') + ' ' + moment(this.formData.value.lunchStartTime).format('HH:mm:ss'), this.timezone);
    let lend = moment.tz(moment(this.formData.value.startDate).format('YYYY-MM-DD') + ' ' + moment(this.formData.value.lunchEndTime).format('HH:mm:ss'), this.timezone);

    let data = {
      startDate: tstart,
      endDate: tend,
      lunchStartTime: lstart,
      lunchEndTime: lend,
      id: '',
      status: this.formData.value.status,
      description: this.formData.value.description
    }
    let append = null;
    if (!this.newSlot) {
      append = {id: {$neq: this.availability.id}};
    }
    this.checkConflict(this.formData.value.staffMember_id, tstart, tend, append).subscribe(response => {
      if (response.length == 0 || response.length > 0 && confirm('There are a conflicted days, Do you need to continue and override them?')) {
        if (this.newSlot) {
          let params: { jsonQuery?: any, 'no-page'?: boolean } = {
            jsonQuery: {
              staffMember: {$eq: this.formData.value.staffMember_id},
              id: {$in: response.map(r => r.id)}
            },
            'no-page': false
          };
          if (response.length > 0)
            this.apiService.deleteStaffAvailability(params).pipe(
              concatMap(res => this.apiService.addStaffAvailability(this.formData.value.staffMember_id, data))
            ).subscribe(res => {
              this.close();
              this.toaster.success('Staff availability added successfully.')
            });
          else
            this.apiService.addStaffAvailability(this.formData.value.staffMember_id, data)
              .subscribe(res => {
                this.close();
                this.toaster.success('Staff availability added successfully.')
              });
        } else {
          data.id = this.availability.id;
          this.apiService.updateStaffAvailability(this.formData.value.staffMember_id, data)
            .subscribe(res => {
              this.close();
              this.toaster.success('Staff availability updated successfully.')
            });
        }
      }
    })
  }

  checkConflict(staffId, dateFrom, dateTo, append) {

    let params: { jsonQuery?: any, 'no-page'?: boolean } = {
      jsonQuery: {
        $or: [
          {$and: [{endDate: {$lt: dateTo}}, {endDate: {$gt: dateFrom}}]},
          {$and: [{startDate: {$lt: dateTo}}, {startDate: {$gt: dateFrom}}]},
        ],
        staffMember: {$eq: staffId},
        ...append
      },
      'no-page': true,

    };
    return this.apiService.getStaffsAvailability(params)
  }

  close() {
    if (this.onClose)
      this.onClose.emit(null);
    this.modalRef.hide();
  }

  statusChanged() {
    let val = this.formData.value.status
    if (this.newSlot) {
      let startTime, endTime;
      if (val == 1) {
        startTime = moment(this.formData.value.startTime).set({hour: 12, minute: 0});
        endTime = moment(this.formData.value.endTime).set({hour: 20, minute: 0});
      } else {
        startTime = moment(this.formData.value.startTime).set({hour: 9, minute: 0});
        endTime = moment(this.formData.value.endTime).set({hour: 20, minute: 0});
      }
      this.formData.patchValue({
        startTime: startTime.toDate(),
        endTime: endTime.toDate()
      })
    }
  }

  deleteSlot() {
    let params: { jsonQuery?: any, 'no-page'?: boolean } = {
      jsonQuery: {
        staffMember: {$eq: this.formData.value.staffMember_id},
        id: {$eq: this.availability.id}
      },
      'no-page': false
    };
    this.apiService.deleteStaffAvailability(params).subscribe(res => {
      if (res.code) {
        this.toaster.success('Staff availability deleted successfully..');
        this.close();
      }
    }, err => {
      this.toaster.error('Staff availability didn\'t deleted successfully..')
    })

  }
}
