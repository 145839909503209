import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import RepCustomersViewModel from 'app/pages/reports/viewModels/RepCustomersViewModel';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private baseUrl: string;
  private baseUrl2: string;
  constructor(private http: HttpClient ) {
    this.baseUrl = environment.apiUrl;  
    this.baseUrl2 = environment.api2Url; 
  }
  
  getCustomers(companyId:number,
    salesCategoryId?:number,
    courseId?:number,
    participantStatus?:string,
    startDate?:string,
    endDate?:string
    ): Observable<RepCustomersViewModel[]> {
    let url = `${this.baseUrl}/reports/participants?companyId=${companyId}`;
    if(salesCategoryId)    
      url +=`&salesCategoryId=${salesCategoryId}`;
      if (courseId) url += `&courseId=${courseId}`;
      if (participantStatus) url += `&status=${participantStatus}`;
      if (startDate) url += `&startDate=${startDate}`;
      if (endDate) url += `&endDate=${endDate}`;
    return this.http.get<RepCustomersViewModel[]>(url);
  }

  getPlacesSolid(companyId:number,
    salesCategoryId?:number,
    courseId?:number,
    participantStatus?:string,
    startDate?:string,
    endDate?:string    
    ): Observable<RepCustomersViewModel[]> {
    let url = `${this.baseUrl2}/reports/course-places?companyId=${companyId}`;
    if(salesCategoryId)    
      url +=`&salesCategoryId=${salesCategoryId}`;
      if (courseId) url += `&courseId=${courseId}`;
      if (participantStatus) url += `&status=${participantStatus}`;
      if (startDate) url += `&startDate=${startDate}`;
      if (endDate) url += `&endDate=${endDate}`;
    return this.http.get<RepCustomersViewModel[]>(url);
  }

  getUpcomingAvailability(companyId:number,
    salesCategoryId?:number,
    courseId?:number,    
    startDate?:string,
    endDate?:string
    ): Observable<RepCustomersViewModel[]> {
    let url = `${this.baseUrl2}/reports/upcoming-availability?companyId=${companyId}`;
    if(salesCategoryId)    
      url +=`&salesCategoryId=${salesCategoryId}`;
      if (courseId) url += `&courseId=${courseId}`;      
      if (startDate) url += `&startDate=${startDate}`;
      if (endDate) url += `&endDate=${endDate}`;
    return this.http.get<RepCustomersViewModel[]>(url);
  }
}
