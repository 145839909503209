import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtraToolViewModel } from 'app/viewModels';
import { environment } from 'environments/environment';
import { ExtraTool, ExtraToolBooking, ExtraToolPriceElement } from 'models';
import { DiscountCode } from 'models/DiscountCode';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExtraToolsService {

  private baseUrl: string;
  private baseUrl2: string;
  constructor(private http: HttpClient ) {
    this.baseUrl = environment.apiUrl+"/extra-tools";
    this.baseUrl2 =   environment.api2Url+"/extra-tools";
  }
  
  getAllExtraTools(companyId:number=null,active=false):Observable<ExtraToolViewModel[]>{
    return this.http.get<ExtraToolViewModel[]>(this.baseUrl2+`?companyId=${companyId}&active=${active?1:0}`).pipe(
      map(res=>res.map(item=>{
        let obj =Object.assign(new ExtraToolViewModel(),item);
        obj.priceElements = item.priceElements.map(p=>Object.assign(new ExtraToolPriceElement(),p))
        obj.courses=item.courses.map((c : any)=>{
          return {
            id:c.course.id,
            name:c.course.name,
            maxQty:c.maxQty
          }
        });
        return obj;
      }))
    );
  }

  updateTool(tool:ExtraToolViewModel){
    return this.http.put(`${this.baseUrl}/${tool.id}`,tool);
  }

  addTool(tool:ExtraToolViewModel){
    return this.http.post(`${this.baseUrl}`,tool);
  }
  checkDiscountAgainstExtra(
    extra: ExtraTool,
    discountCode: string
  ): Observable<DiscountCode> {
    return this.http.get<DiscountCode>(
      `${this.baseUrl}/${extra.id}/checkDiscountCodeApplicability?discountCode=${discountCode}`
    );
  }

  addToolToPendingOrder(bookingOrderId,extraTool:any){
    return this.http.post(`${environment.apiUrl}/bookingOrders/${bookingOrderId}/extraTool`,extraTool);
  }

}
