import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {routing} from './app.routing';
import {RouterModule} from '@angular/router';
import {GlobalState} from './global.state';
import {AppState, InternalStateType} from './app.service';
import {NgaModule} from './theme/nga.module';
import {PagesModule} from './pages/pages.module';
import {AuthenticationService} from './_services';
import {AuthGuard} from './_guards/auth/auth.guard';
import {NoAuthGuard} from './_guards/no-auth/no-auth.guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import * as moment from 'moment';
import {environment} from '../environments/environment';
import {BsDatepickerModule, BsDropdownModule, PaginationModule, TabsModule, TimepickerModule, TypeaheadModule} from 'ngx-bootstrap';
import {CacheService} from './_services/cache/cache.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './_interceptors/token.interceptor';
import {PendingChangesGuard} from './_guards/pending-changes/pending-changes.guard';
import {PermissionsGuard} from './_guards/permissions/permissions-guard';

import {BigNumber} from 'bignumber.js';
import {ToastrModule} from 'ngx-toastr';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import * as Sentry from '@sentry/browser';
 
BigNumber.config({DECIMAL_PLACES: 10});

export class SentryErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    console.error(err);
    Sentry.captureException(err.originalError || err);
    Sentry.showReportDialog({
      title: 'Something has gone wrong. Refresh your page before retrying',
      subtitle: 'Please complete this form it really helps us fix things for you and other users',
      subtitle2: 'PLEASE REFRESH YOUR PAGE BEFORE RETRYING'
    })
  }
}

export function provideErrorHandler() {
  if (environment.production) {
    Sentry.init({dsn: 'https://0309ce289b20450f8ce46e2cdc7aa2b6@sentry.io/238338', release: 'v1.7.1'});
    return new SentryErrorHandler();
  }

  return new ErrorHandler();
}

const APP_PROVIDERS = [
  AppState,
  GlobalState
];

export type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    NgaModule.forRoot(),
    routing,
    PagesModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    PaginationModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    TimepickerModule.forRoot(),
    // MomentTimezoneModule,
  ],
  providers: [
    ...APP_PROVIDERS,
    AuthenticationService,
    CacheService,
    AuthGuard,
    NoAuthGuard,
    PendingChangesGuard,
    PermissionsGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler, useFactory: provideErrorHandler
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    const locale = window.navigator.language;
    moment.locale(locale);
  }
}
