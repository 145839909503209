import {Contact} from './Contact';
import {Membership} from './Membership';

export class ContactWithRelationType extends Contact {
  type?: ContactRelationType;
  membership?: Membership;
  membershipError?: any;

  deserialize(input) {
    if (!input) {
      return null;
    }
    super.deserialize(input);
    this.type = input.type;
    this.membership = new Membership().deserialize(input.membership);
    this.membershipError = input.membershipError;
    return this;
  }
}

export type ContactRelationType = 'Friend' | 'Partner' | 'Child' | 'Self';
