import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import RepIncomeViewModel from "app/pages/reports/viewModels/RepIncomeViewModel";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiReportsService {
  private baseUrl2: string;
  constructor(private http: HttpClient) {
    this.baseUrl2 = environment.api2Url + "/reports/income";
  }

  getIncome(
    companyId:number,
    locationId: number,
    startDate: string,
    endDate: string
  ): Observable<RepIncomeViewModel[]> {
    return this.http.get<RepIncomeViewModel[]>(
      `${this.baseUrl2}?companyId=${companyId}&locationId=${locationId||''}&startDate=${startDate||''}&endDate=${endDate||''}`
    );
  }
}
