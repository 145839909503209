import {Company} from './Company';
import {Course} from './Course';
import {UUID} from '../app/utils/Utils';
import {PriceElement} from './PriceElement';

export class CoursePriceElement extends PriceElement {
  id: number | string;
  company: Company;
  course: Course;
  description: string;
  amount: string;
  applyTax: boolean = false;


  constructor() {
    super();
    this.id = UUID();
    this.company = new Company();
  }
}
