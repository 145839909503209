import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParticipantStatusModalComponent } from 'app/pages/courseCalendar/participant-status-modal/participant-status-modal.component';
import { normalize } from 'app/utils/Utils';
import BookingParticipantViewModel from 'app/viewModels/BookingParticipantViewModel';
import { CourseRunBookingViewModel } from 'app/viewModels/CourseRunBookingViewModel';
import { CourseRunParticipantsViewModel } from 'app/viewModels/CourseRunParticipantsViewModel';
import { ApiCourseRunService, AuthenticationService } from 'app/_services';
import { ApiService } from 'app/_services/api.service';
import { Contact } from 'models/Contact';
import { Course } from 'models/Course';
import { CourseRun } from 'models/CourseRun';
import { CourseRunBooking } from 'models/CourseRunBooking';
import { WaiverParticipant } from 'models/WaiverParticipant';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'course-participants',
  templateUrl: './course-participants.component.html',
  styleUrls: ['./course-participants.component.scss']
})
export class CourseParticipantsComponent implements OnInit {
  @Input() subTitle:string="";
  @Input() course:Course;
  @Input() courseRunId:number;  
  signOutForm:FormGroup;
  participants$: Observable<CourseRunParticipantsViewModel[]>;
  user: Contact;
  showSignIn: boolean;
  showSignOutModal: boolean;
  currentParticipant:CourseRunParticipantsViewModel;
  leadBooker:CourseRunParticipantsViewModel;
  haveParticipants: boolean;
  haveNoneParticipants: any;
  $participants:Observable<BookingParticipantViewModel[]>;
  constructor( private apiSvc: ApiService,
    private auth:AuthenticationService,
    private modalSvc: BsModalService,
    private toastySvc: ToastrService,
    private _fb : FormBuilder,
    private apiCourseRun:ApiCourseRunService

    ) {
      
     }

  ngOnInit() {
    this.getData();
   }
  getData(){
    if(this.courseRunId)
      this.$participants = this.apiCourseRun.getCourseRunParticipants(this.courseRunId)
      .pipe(map(participants=>{
        const waivers= [...[].concat(...participants.map(p=>p.contact.waivers))
             ,...[].concat(...participants.map(p=>[].concat(...p.contact.childOf.map(c=>c.waivers))))] ;

        return participants.map(p=>{
        const [waiverParticipant,signToken]=this.getParticipantWaiver(p)
         || this.getParticipantWaiverFromAll(p,participants,waivers)
         ||[null,null];
        //---------calc age
        let dt=p.contact.dob;
        if(waiverParticipant &&   waiverParticipant.dateOfBirth &&(dt ==null || moment(waiverParticipant.dateOfBirth)<moment(dt)) )
           dt=waiverParticipant.dateOfBirth;
        if(dt)
          p.contact.age =moment().diff(moment(dt),'y');
        //----------
        if(waiverParticipant)
        {
          p.medicalConditions=(p.contact.medicalConditions||'') +' '+ (waiverParticipant.medicalConditions||'');
          p.isSwimmer = waiverParticipant.isSwimmer ==1 ;
          p.emergancyPhone=waiverParticipant.emergancyPhone || (p.contact.emergencyContactNumber||'')||'';
          p.foodAllergy=waiverParticipant.food_allergy;
          p.signToken = signToken;
        }
         p.leadBooker = p.courseRunBooking.groupBooking && (p.contact.id == p.courseRunBooking.customer_id);
        return p;
      })})
      ) 
      .pipe(tap(res=>{
        this.haveParticipants=this.checkParticipants(res);
        this.haveNoneParticipants=this.checkNoneParticipants(res);
        this.leadBooker=this.checkLeadBooker(res);
      }));   
      /*
    this.participants$=this.apiSvc.getCourseRunParticipants(this.courseRunId)
    .pipe(map(participants=>participants.map(p=>{
      const [waiverParticipant,signToken]=this.getParticipantWaiver(p) || this.getParticipantWaiverFromAll(p,participants)||[p.waivers[0].participants&& p.waivers[0].participants[0],p.waivers[0].signToken] ||[null,null];
      //---------calc age
      let dt=p.contact.dob;
      if(waiverParticipant &&   waiverParticipant.dateOfBirth &&moment(waiverParticipant.dateOfBirth)<moment(dt) )
         dt=waiverParticipant.dateOfBirth;
      if(dt)
        p.age =moment().diff(moment(dt),'y');
      console.log({waiverParticipant});
      //----------
      if(waiverParticipant)
      {
        p.medicalConditions=waiverParticipant.medicalConditions;
        p.isSwimmer = waiverParticipant.isSwimmer ==1 ;
        p.emergancyPhone=waiverParticipant.emergancyPhone;
        p.foodAllergy=waiverParticipant.food_allergy;
        p.signToken = signToken;
      }
       p.leadBooker = p.courseRunBooking.groupBooking && (p.contact.id == p.courseRunBooking.contactId);
      return p;
    }))) 
    .pipe(tap(res=>{
      this.haveParticipants=this.checkParticipants(res);
      this.haveNoneParticipants=this.checkNoneParticipants(res);
      this.leadBooker=this.checkLeadBooker(res);
      console.log(res)
    }));   
*/
    this.auth.getProfileData() .subscribe(user=>{
      this.user=user;
      
    })
  }
  
  changeParticipantStatus($event, participant: BookingParticipantViewModel) {
    const notes=prompt("Are you sure you want to change the status of this participant?");
    if(notes !== null)
    {
          participant.status = $event;          
          participant.notes=notes;
          this.apiSvc.updateBookingParticipant({id:participant.id,notes,status:participant.status})
          .pipe(
            map(success => {              
              this.toastySvc.success('Updated status');
              return true;
            }),
            catchError(error => {
              this.toastySvc.error('Failed to update status');
              return of(false);
            })
          ).subscribe(res=>{

          });
    }      
  }
  handleShowSignIn(participant:BookingParticipantViewModel){
    const signToken= participant.contact.waivers.filter(w=>w.signToken !="");
    if(this.course.requireSignIn && !signToken)
    {
      alert("Client Must sign the course waiver before sign in.");
      return;
    }
    this.showSignIn = true;    
  }
  handleSignClose(){
    this.showSignIn = false;
    this.showSignOutModal = false;
    this.currentParticipant = null;
    this.getData();
  }
  setCurrentParticipant(participant){
    this.currentParticipant = participant;
   }

   handleShowSignOut(){
    this.showSignOutModal = true;
  }
  
  getParticipantWaiver(participant:BookingParticipantViewModel):[WaiverParticipant,string]
  {
    
    const waivers = [...participant.contact.waivers,...[].concat(...participant.contact.childOf.map(c=>c.waivers))];
    for(let i=0; i<waivers.length; i++)
    {
      const waiver = waivers[i];
      if( waiver.participants)
        for(let j=0 ; j<waiver.participants.length;j++)
        {
          if(waiver.participants[j] && normalize(waiver.participants[j].firstName+waiver.participants[j].lastName) == 
          normalize(participant.contact.firstName+participant.contact.lastName)
          )
          {
            waiver.participants[j].emergancyPhone = waiver.emergancyPhone;
            waiver.participants[j].emergancyName = waiver.emergancyName;
            return  [waiver.participants[j],waiver.signToken] ;
          }
        }
    }
    if(waivers.length>0){
      let waiver = waivers[0];
      if(waiver)
      return  [waiver.participants[0],waiver.signToken];

    }
    return null;
  }
  /**
   * @WaiverParticipant Use this function to search in all partcipants waivers
   * @param participant 
   * @param participants 
   * @returns 
   */
  getParticipantWaiverFromAll(participant:BookingParticipantViewModel,participants:BookingParticipantViewModel[],waivers:any[]):[WaiverParticipant,string]
  {
     
      for(let i=0; i<waivers.length; i++)
    {
      const waiver = waivers[i];
      if( waiver.participants)
        for(let j=0 ; j<waiver.participants.length;j++)
        {
          if(waiver.participants[j] && normalize(waiver.participants[j].firstName+waiver.participants[j].lastName) == 
          normalize(participant.contact.firstName+participant.contact.lastName)
          )
          {
            waiver.participants[j].emergancyPhone = waiver.emergancyPhone;
            waiver.participants[j].emergancyName = waiver.emergancyName;
            return  [waiver.participants[j],waiver.signToken] ;
          }
        }
    }
    return [null,null];   
  }

  checkParticipants(participants:BookingParticipantViewModel[]){
    return participants.filter(participant=>participant.status=='confirmed' && !participant.leadBooker).length>0;
  }

  checkNoneParticipants(participants:BookingParticipantViewModel[]){
    return participants.filter(participant=>participant.status !=='confirmed' && !participant.leadBooker).length>0;
  }

  checkLeadBooker(participants){
    return participants.filter(participant=>participant.leadBooker)[0];
  }

  getLeadBooker(participants:BookingParticipantViewModel[]){
    const p = participants.filter((p) => {
      p.courseRunBooking.groupBooking &&
        p.contact.id == p.courseRunBooking.customer_id;
    });
    if (p.length > 0) return p[0];
    return null;
  }
  // getParticipants(participants:BookingParticipantViewModel[]){
  //   const p = participants.filter((p) => {
  //     !p.courseRunBooking.groupBooking &&  p.contact.id == p.courseRunBooking.customer_id;
  //   });
   
  // }
}
