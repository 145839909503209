import { Component, OnInit, EventEmitter, ViewChild, Output, Input } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'app/_services/api.service';
import { debounceTime, tap, switchMap, finalize, takeUntil, take, map, startWith } from 'rxjs/operators';
import { isObject } from 'util';
import { of, Subject } from 'rxjs';
import { Company } from 'models/Company';
import { AddClientModalComponent } from 'app/pages/clientBook/addClientModal/add-client-modal.component';
import { Contact } from 'models/Contact';
import { ToastrService } from 'ngx-toastr';
import { CustomerCompany } from 'models/CustomerCompany';
import * as _ from 'lodash';
@Component({
  selector: 'customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss']
})
export class CustomerSelectorComponent implements OnInit {
  private _customerCompany: CustomerCompany;
  public _enableNew=true;
  @Input() fullLoad = false;
  @Input() set customerCompany(value: CustomerCompany) {
  this._customerCompany = value;
    if (value) {
      //console.log('Company changed', value.id);
      this.filteredCustomers = value.allowedContacts;
    }
  }
  get() {
    return this._customerCompany;
  }
  @Input() currentCompany: Company;
  @Input() customerCompanyOnly = false;
  @Input() width;
  @Input('customer')
  set customer(val) {    
    this.selectedCustomerControl.patchValue(val==null?'':val);
  }
  @Input() 
  set enableNew(value:boolean){
    this._enableNew = value;
  }
  @Output() selectedCustomer: EventEmitter<any> = new EventEmitter();
  isLoading = false;
  filteredCustomers = [];
  selectedCustomerControl: FormControl;
  modalRef: BsModalRef;
  customerSearchValue = '';
  onDestroy$ = new Subject<Boolean>();
  constructor(private apiSvc: ApiService, private modalService: BsModalService,
    private toastySvc: ToastrService
  ) {
    this.selectedCustomerControl = new FormControl();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
  }
  ngOnInit() {
    this.searchCustomer();
  }

  displayFn(Customer) {
    if (Customer) {
      return Customer.firstName + ' ' + Customer.lastName;
    }
  }
  searchCustomer() {
    let params = {
      jsonQuery: {

      },
      'no-page': true,
      order: 'firstName',
      groups: ['contactSummary'],
    };
    if (this.currentCompany) {
      params = {
        ...params,
        jsonQuery: { 'company.id': this.currentCompany.id }
      }
    }

    this.selectedCustomerControl.valueChanges
      .pipe(
        debounceTime(1000),
        tap((value) => {
          this.isLoading = true;
          this.customerSearchValue = value;
          if (!isObject(this.customerSearchValue)&&value!=null&&value!='') {           
            let orArray = value.split(' ').reduce((acc, item) => {
              acc = [
                ...acc,
                {
                  $or: [
                    { firstName: { $contains: item } },
                    { lastName: { $contains: item } }
                  ]
                }
              ];
              return acc;
            }, []);

            params.jsonQuery = {
              ...params.jsonQuery,
              $and: orArray
            };
          }
        })
        , switchMap(value => {
          if (value != null&&value != '' && value.length>=3) {
            
            if (!isObject(this.customerSearchValue) && this.customerSearchValue != '') {
              if (this.customerCompanyOnly ) {
                if (!this._customerCompany)
                  this.toastySvc.error('Please select company');
                //console.log('============>', this._customerCompany);
                let array = _.filter(this._customerCompany.allowedContacts, function (item) {
                  return _.includes((item.firstName + ' ' + item.lastName).toLowerCase(), value.toLowerCase());
                });
                return of(array);
              }
              else
                return this.apiSvc.getCustomers(params).pipe(
                  map(response => response.data),
                  finalize(() => this.isLoading = false))
            }
            else return of([]);
          }
          else return of([]);

        }
        ),
        takeUntil(this.onDestroy$)
      ).subscribe((response: any) => {
        ////console.log(response);
        this.filteredCustomers = response;
        this.isLoading = false;
      })
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    if (this.selectedCustomer)
      if (this.fullLoad) {
        this.apiSvc.getContact(event.option.value.id).pipe(takeUntil(this.onDestroy$))
          .subscribe(contact => {
            this.selectedCustomer.emit(contact);
          });
      }
      else
        this.selectedCustomer.emit(event.option.value);

  }
  CustomerAdded(event) {
    ////console.log('new Customer =>',event);
    this.selectedCustomer.emit(event);
    this.modalService.hide(1);
    this.selectedCustomerControl.setValue({ firstName: event.firstName, lastName: event.firstName });

  }
  addClient() {
    console.log(this._customerCompany)
    if (!this._customerCompany) {
      this.toastySvc.error('Please choose a Company');
      return;
    }
    const modal = this.modalService.show(AddClientModalComponent);
    const component = <AddClientModalComponent>modal.content;
    component.client = new Contact();
    component.client.company = this.currentCompany;
    component.client.billingAddress.country = this.currentCompany.preferredCountry;

    this.modalService.onHidden.pipe(
      take(1)
    ).subscribe((reason) => {
      if (reason == 'changes-saved') {
        this.toastySvc.success('Client created successfully');


        if (this._customerCompany) {
          this._customerCompany.allowedContacts.push(component.client);
          //console.log(component.client, { name: component.client.firstName + ' ' + component.client.lastName });
          this.selectedCustomerControl.setValue({ firstName: component.client.firstName, lastName: component.client.lastName });
          this.apiSvc.updateCustomerCompany(this._customerCompany).pipe(takeUntil(this.onDestroy$)).subscribe(res => this.selectedCustomer.emit(component.client));
        }
      }

      if (reason == 'failed') {
        this.toastySvc.error('Failed to create client');
      }
    })
  }
  isAllowed(customer)
  {
    if(this._customerCompany&&this._customerCompany.allowedContacts)
    {
    const indx=this._customerCompany.allowedContacts.findIndex(c=>c.id==customer.id);    
    return indx>=0;
    }
    else return false;
  }
}
