import {Component, OnDestroy} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ApiService} from '../../../_services/api.service';
import {Contact} from '../../../../models/Contact';


import {Observable, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ContactRelationType, ContactWithRelationType} from '../../../../models/ContactWithRelationType';

@Component({
  selector: 'pavweb-add-client-contact-modal',
  templateUrl: './add-client-contact-modal.component.html',
  styleUrls: ['./add-client-contact-modal.component.scss']
})
export class AddClientContactModal implements OnDestroy {
  selectedContactType: ContactRelationType = 'Partner';

  searchStep = true;

  test = {
    inputTest: '312'
  };

  searchData = {
    type: 'email',
    value: null
  };

  customer: Contact;

  error;

  addedContact: ContactWithRelationType;
  private _addedContactSubject: Subject<Contact> = new Subject<Contact>();
  onAddedContact: Observable<Contact> = this._addedContactSubject.asObservable().pipe(tap(console.log));

  constructor(public bsModalRef: BsModalRef, public apiSvc: ApiService, private modalSvc: BsModalService) {
  }

  ngOnDestroy() {
    //console.log('completing');
    this._addedContactSubject.complete();
  }

  searchFriend() {
    this.apiSvc.searchAndAddContactFriend(
      this.customer.id,
      this.searchData.type == 'email' ? this.searchData.value : null,
      this.searchData.type == 'username' ? this.searchData.value : null
    ).subscribe((friendship) => {
      this.addedContact = new ContactWithRelationType().deserialize({...friendship.friend, type: 'Friend'});
      this.modalSvc.setDismissReason('addedFriend');
      this._addedContactSubject.next(this.addedContact);
      this.bsModalRef.hide();
    }, (errorData) => {
      let errorMsg = 'Connection Failed try again later.';
      if (errorData.error) {
        if (errorData.error.code === 533) {
          this.searchData.value = null;
          errorMsg = null;
          this.searchStep = false;
        }

        if (errorData.error.code === 1024) {
          errorMsg = 'The given userName or Email belongs to someone that is already your friend';
        }
      }

      this.error = errorMsg
    })
  }

  submitForm(form) {
    if (!form.valid) {
      Object.keys(form.controls).forEach(field => {
        //console.log(field);
        const control = form.controls[field];
        control.markAsTouched({onlySelf: true});
      });
      return;
    }

    //we know that all required fields are correct from this stage

    let observable;

    switch (this.selectedContactType) {
      case 'Partner':
        observable = this.apiSvc.addContactPartner(this.customer.id, form.value);
        break;
      case 'Child':
        observable = this.apiSvc.addContactChild(this.customer.id, form.value).pipe(
          map(childOf => {
            return childOf.child;
          })
        );
        break;
      case 'Friend':
        observable = this.apiSvc.addContactFriend(this.customer.id, form.value).pipe(
          map(friendship => {
            return friendship.friend
          })
        );
        break;
    }


    observable.subscribe(contact => {
      this.addedContact = {
        ...contact,
        type: this.selectedContactType
      };
      this.modalSvc.setDismissReason(AddClientContactModalReason.ADDED_CONTACT);
      this._addedContactSubject.next(this.addedContact);
      this.bsModalRef.hide();
    })
  }
}

export enum AddClientContactModalReason {
  ADDED_CONTACT = 'added-contact'
}
