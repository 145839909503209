import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { EmailTemplate } from 'models/EmailTemplate';
import { EmailTemplateType } from 'models/EmailTemplateType';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiEmailTemplatesService {

  private baseUrl2: string;
  constructor(private http: HttpClient ) {
     this.baseUrl2 =   environment.api2Url+"/email-templates";
  }
  getTemplates(companyId:number){      
    return this.http.get<EmailTemplate[]>(`${this.baseUrl2}/${companyId}`);
  }
  getTemplate(companyId:number,templateType:string,locationId:number,salesCategoryId:number){      
    return this.http.get<EmailTemplate>(`${this.baseUrl2}/${companyId}/${templateType}?locationId=${locationId||''}&salesCategoryId=${salesCategoryId||''}`);
  }
  getTemplateTypes(){      
    return this.http.get<EmailTemplateType[]>(`${this.baseUrl2}/types`);
  }

  updateTemplate(emailTemplate:EmailTemplate):Observable<EmailTemplate>{
    return this.http.put<EmailTemplate>(`${this.baseUrl2}`,emailTemplate);
  }
}
