import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {GlobalState} from '../../../global.state';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../_services';
import {Company} from '../../../../models/Company';
import {ApiService} from '../../../_services/api.service';
import {SystemCompanyService} from '../../../_services/system-company.service';

import * as moment from 'moment';
import {combineLatest, Observable, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'ba-page-top',
  templateUrl: './baPageTop.html',
  styleUrls: ['./baPageTop.scss'],
  changeDetection: ChangeDetectionStrategy.Default,

})
export class BaPageTopComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private onDestroy$ = new Subject();
  public momentRef = moment;
  private hasChanged = false;
  public isScrolled = false;
  public isMenuCollapsed = false;

  selectedCompany: Company;

  companies$: Observable<Company[]>;
  public firstName$: Observable<string>;
  public isSuperAdmin: boolean;
  companies: Company[];

  constructor(private _state: GlobalState, private authenticationService: AuthenticationService, private router: Router,
              private apiSvc: ApiService,
              private companySvc: SystemCompanyService,
              private cdref: ChangeDetectorRef) {
    this.isSuperAdmin = false;

  }

  ngOnInit() {


    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });

    this.companySvc.getCompany().pipe(
      tap((company) => {
        debugger;
        if (!this.hasChanged) {
          this.selectedCompany = company;
        }
        this.hasChanged = false;
      }), takeUntil(this.onDestroy$)
    );

    this.companies$ = combineLatest(
      this.apiSvc.getCompanies({'no-page': true}).pipe(
        map(companyCollection => companyCollection.data)
      ),
      this.authenticationService.getProfileData()
    ).pipe(
      map(([companies, profileData]) => {
        //console.log(profileData);
        this.selectedCompany = companies[0];
        if (profileData.staffMember && profileData.staffMember.primaryCompany) {
          this.selectedCompany = companies.find((company) => {
            return company.id === profileData.staffMember.primaryCompany.id;
          });
        }

        this.companySvc.setCompany(this.selectedCompany);
        return companies;
      })
    );

    this.firstName$ = this.authenticationService.getProfileData().pipe(
      tap(profileData => {
        this.isSuperAdmin = profileData.staffMember && profileData.staffMember.role == 'super_admin';
      }),
      map(data => data.firstName));
    this.cdref.detectChanges();
    this.companies$.subscribe(response => {
      this.companies = response;
    });
  }

  public toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    return false;
  }

  public scrolledChanged(isScrolled) {
    this.isScrolled = isScrolled;
  }

  public signOut() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  compareWithId(a, b) {
    return a && b && a.id === b.id;
  }

  changedCompany(company) {
    this.hasChanged = true;
    this.companySvc.setCompany(company);
  }
}
