import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

export type RenderFunc = (data: any, row?: any) => any;


export interface Column {
  render?: RenderFunc;
}

@Component({
  selector: 'app-table',
  template: `
    <table class="table dataTable "  ngClass="{{config.className || ''}}"
           role="grid" style="width: 100%;color: white">  
      <thead>
      <tr role="row">
        <th *ngFor="let column of columns" [ngTableSorting]="config" [column]="column"
            (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
          {{column.title}}
          <i *ngIf="config && column.sort" class="pull-right fa"
             [ngClass]="{'fa-chevron-down': column.sort === 'desc', 'fa-chevron-up': column.sort === 'asc'}"></i>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="showFilterRow">
        <td *ngFor="let column of columns">
          <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
                 [ngTableFiltering]="column.filtering"
                 class="form-control"
                 style="width: auto;"
                 (tableChanged)="onChangeTable(config)"/>
        </td>
      </tr>
      <tr *ngFor="let row of rows">
        <td (click)="cellClick(row, column.name)" *ngFor="let column of columns" (contextmenu)="contextMenuClick(row, column.name, $event)"
            [innerHtml]="sanitize(getData(row, column.name))"></td>
      </tr>
      </tbody>
    </table>
  `
})
export class NgTableComponent {
  // Table values
  @Input() public rows: Array<any> = [];

  @Input()
  public set config(conf: any) {
    if (!conf.className) {
      conf.className = 'table-striped table-bordered';
    }
    if (conf.className instanceof Array) {
      conf.className = conf.className.join(' ');
    }
    this._config = conf;
  }

  // Outputs (Events)
  @Output() public tableChanged: EventEmitter<any> = new EventEmitter();
  @Output() public cellClicked: EventEmitter<any> = new EventEmitter();
  @Output() public contextMenuClicked: EventEmitter<any> = new EventEmitter();

  public showFilterRow: Boolean = false;

  @Input()
  public set columns(values: Array<any>) {
    values.forEach((value: any) => {
      if (value.filtering) {
        this.showFilterRow = true;
      }
      if (value.className && value.className instanceof Array) {
        value.className = value.className.join(' ');
      }
      const column = this._columns.find((col: any) => col.name === value.name);
      if (column) {
        Object.assign(column, value);
      }
      if (!column) {
        this._columns.push(value);
      }
    });
  }

  private _columns: Array<any> = [];
  private _config: any = {};

  public constructor(private sanitizer: DomSanitizer) {
  }

  public sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public get columns(): Array<any> {
    return this._columns;
  }

  public get config(): any {
    return this._config;
  }

  public get configColumns(): any {
    const sortColumns: Array<any> = [];

    this.columns.forEach((column: any) => {
      if (column.sort) {
        sortColumns.push(column);
      }
    });

    return {columns: sortColumns};
  }

  public onChangeTable(column: any): void {
    this._columns.forEach((col: any) => {
      if (col.name !== column.name && col.sort !== false) {
        col.sort = '';
      }
    });
    this.tableChanged.emit({sorting: this.configColumns});
  }

  public getData(row: any, propertyName: string): string {
    const data = propertyName.split('.').reduce(function (prev, curr) {
      if (!prev || !prev[curr]) {
        return null;
      }
      return prev[curr];
    }, row);

    if (data === null) {
      return null;
    }

    const foundColumn = this.columns.find((column) => {
      return column.name === propertyName;
    });

    if (foundColumn) {
      const test = <Column>foundColumn;
      if (typeof test.render !== 'undefined') {
        return test.render(data, row);
      }
    }

    return data;
  }

  public cellClick(row: any, column: any): void {
    this.cellClicked.emit({row, column});
  }

  public contextMenuClick(row: any, column: any, $event: any): void {
    this.contextMenuClicked.emit({row, column, $event})
  }
}
