import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { SalesCategory } from 'models/SalesCategory';

@Injectable({
  providedIn: 'root'
})
export class ApiSalesCategoriesService {
  private baseUrl2: string;
  constructor(private http: HttpClient) {
    this.baseUrl2 = environment.api2Url + "/sales-categories";
  }
  
  getSalesCategories( companyId:number){
    return this.http.get<SalesCategory[]>(
      `${this.baseUrl2}/${companyId}`
    );
  }
  createSalesCategory(data:SalesCategory ){
    return this.http.post<SalesCategory>(`${this.baseUrl2}`,data);
  }
  updateSalesCategory(data:SalesCategory ){
    return this.http.put<SalesCategory>(`${this.baseUrl2}`,data);
  }
}
