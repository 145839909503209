import {CourseRun} from './CourseRun';
import {CourseRunBookingStatus} from './CourseRunBookingStatus';
import {Contact} from './Contact';
import BigNumber from 'bignumber.js';
import {DiscountCode, DiscountType} from './DiscountCode';
import {BookingOrder} from './BookingOrder';
import {CustomerCompany} from './CustomerCompany';
import {Offer} from './Offer';
import {BookingParticipant} from './BookingParticipant';
import {Serializable} from './Serializable';
import {PackageCourse} from './PackageCourse';
import {PackageCustomer} from './PackageCustomer';

export class CourseRunBooking implements Serializable<CourseRunBooking> {
  id: number;
  customer: Contact;
  courseRun: CourseRun;
  status: CourseRunBookingStatus;
  participants: BookingParticipant[] = [];
  groupParticipantsCount = 0;
  groupPeopleBooked = 0;
  discountCode: DiscountCode;
  order?: BookingOrder;
  customerCompany: CustomerCompany;
  totalAmount?: string;
  groupBooking?: boolean = false;
  packageCourse: PackageCourse;
  packageCustomer: PackageCustomer;
  signInContactNumber: string;
  signInDate: string;
  signInMissAction: string;
  signInTimeOfReturn: string;
  signOutDate: string;
  signOutNotes: string;
  contactId: number;
  customer_id: number;
  constructor() {
    this.customer = new Contact();
    this.courseRun = new CourseRun;
    this.status = CourseRunBookingStatus.PENDING;
    this.order = new BookingOrder();
  }

  getTotalPrice(): BigNumber {
    return this.getGrossTotal();
  }

  private getGrossTotal(): BigNumber {

    if (this.hasMembership()) {
      // we need to adjust the price per participant, depending on the amount of discount each subscription gives them

      return this.participants.reduce((acc: BigNumber, participant) => {
        if (!participant.membership) {
          return acc.plus(this.getBaseGross());
        }

        // we have a membership..we need to check what is the discount to be applied to the course 100 - 0
        const discount = participant.membership.getCourseDiscount(this.courseRun.course);
        const baseGrossBN = new BigNumber(this.getBaseGross());

        return acc.plus(baseGrossBN.minus(baseGrossBN.times(new BigNumber(discount).dividedBy(100))));
      }, new BigNumber(0));
    }
    if (this.hasPackageCourse()) {
      return new BigNumber(0);
    }
    return this.getGrossWithOffer().minus(this.getDiscountAmount());
  }

  private getGrossWithOffer(): BigNumber {
    const baseGrossBN = new BigNumber(this.getBaseGross());
    return (baseGrossBN.multipliedBy(this.getParticipantsCount()).minus(this.getAffectingOffersGrossToDiscountAmount()));
  }

  private getDiscountAmount(): BigNumber {
    if (!this.discountCode) {
      return new BigNumber(0);
    }
    if (this.discountCode.type === DiscountType.FIXED_AMOUNT) {
      return new BigNumber(this.discountCode.amount);
    }

    return this.getGrossWithOffer().multipliedBy(this.discountCode.amount).div(100);
  }

  private getBaseGross() {
    return this.getBaseNet().plus(this.getBaseTax()).toFixed(2);
  }

  public getParticipantsCount(): number {
    if (this.isGroupBooking()) {
      return this.groupParticipantsCount ? this.groupParticipantsCount : 0;
    }

    return this.participants.length;
  }

  private getBaseNet(): BigNumber {
    return this.courseRun.priceElements.reduce((acc, priceElement) => {
      return acc.plus(priceElement.amount);
    }, new BigNumber(0));
  }

  private getBaseTax(): BigNumber {
    return this.courseRun.priceElements.reduce((acc, priceElement) => {
      const taxAmount = priceElement.applyTax ? this.courseRun.billingCompany.taxRate : 0;
      return acc.plus(new BigNumber(priceElement.amount).multipliedBy(taxAmount).div(100));
    }, new BigNumber(0));
  }

  private getAffectingOffersGrossToDiscountAmount(): BigNumber {
    const grossToDiscount = new BigNumber(0);

    if (!this.order) {
      return grossToDiscount;
    }

    const offers = this.order.offers;

    if (!offers.length) {
      return grossToDiscount;
    }

    const affectingOffers = this.order.offers.filter((offer) => {
      return offer.affectedCourses.find((affectedCourse) => {
        return affectedCourse.id === this.courseRun.course.id;
      });
    });

    return affectingOffers.reduce((grossToDiscountAcc, affectingOffer) => {
      const timesToApply = this.order.getOfferMaxCount(affectingOffer);

      return grossToDiscountAcc.plus(
        this.getOfferDiscountAmount(affectingOffer).multipliedBy(timesToApply)
      );

    }, grossToDiscount);

  }

  public isGroupBooking(): boolean {
    return this.courseRun.course.groupCourse;
  }

  private getOfferDiscountAmount(offer: Offer): BigNumber {
    if (offer.discountType === DiscountType.FIXED_AMOUNT) {
      return new BigNumber(offer.amount);
    }
    const baseGrossBN = new BigNumber(this.getBaseGross());
    if (offer.discountType === DiscountType.PERCENTAGE) {
      return baseGrossBN.multipliedBy(offer.amount).div(100);
    }
    return new BigNumber(0);
  }

  hasMembership() {
    //  console.log(this.participants);
    return this.participants.some(participant => !!participant.membership);
  }

  deserialize(input): CourseRunBooking {
    if (!input) {
      return null;
    }

    this.id = input.id;
    this.customer = new Contact().deserialize(input.customer);
    this.courseRun = new CourseRun().deserialize(input.courseRun);
    this.status = input.status;
    if (input.participants) {
      this.participants = input.participants.map(item => {
        const participant = new BookingParticipant().deserialize(item);
        return participant;
      });
    }
    this.groupParticipantsCount = input.groupParticipantsCount;
    this.groupPeopleBooked = input.groupPeopleBooked;
    this.discountCode = new DiscountCode().deserialize(input.discountCode);
    this.order = new BookingOrder().deserialize(input.order);
    this.customerCompany = new CustomerCompany().deserialize(input.customerCompany);
    this.totalAmount = input.totalAmount;
    this.groupBooking = input.groupBooking;
  }

  hasPackageCourse() {
    return this.packageCourse ? true : false;
  }
}


