import {Invoice} from './Invoice';
import {UUID} from '../app/utils/Utils';
import {Serializable} from './Serializable';

export class InvoiceItem implements Serializable<InvoiceItem> {
  id: string | number;
  description: string;
  amount: string;
  taxPercentage: number;
  quantity: number;
  readOnly: boolean;
  invoice: Invoice;


  constructor() {
    this.invoice = new Invoice();
    this.id = UUID();
    this.quantity = 1;
  }

  deserialize(input) {
    if (!input) {
      return null;
    }

    this.id = input.id;
    this.description = input.description;
    this.amount = input.amount;
    this.taxPercentage = input.taxPercentage;
    this.quantity = input.quantity;
    this.readOnly = input.readOnly;
    this.invoice = new Invoice().deserialize(input.invoice);

    return this;
  }
}
