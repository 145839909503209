export const TemplateVariables = [
    { id: "voucher_email", title: "Voucher Email",code:'[voucher_email]' ,description:"Voucher Email" },
    { id: "purchaser_name", title: "Purchaser Name",code:'[purchaser_name]'  ,description:"Purchaser Name" },
    { id: "assigned_name", title: "Assigned Name",code:'[assigned_name]'  ,description:"Voucher assigned Name" },
    { id: "voucher_reference", title: "Voucher Reference",code:'[voucher_reference]'  ,description:"Voucher Reference" },
    { id: "voucher_message", title: "Voucher Message",code:'[voucher_message]'  ,description:"Voucher Message" },
    { id: "voucher_expiryDate", title: "Voucher Expire Date",code:'[voucher_expiryDate]'  ,description:"Voucher Expire Date" },    
    { id: "voucher_link", title: "Voucher Link",code:'[voucher_link]'  ,description:"Voucher Link" },    
    { id: "voucherImage", title: "Voucher Image",code:'[voucherImage]'  ,description:"Voucher Image" },    
    { id: "voucher_date", title: "Voucher Date",code:'[voucher_date]'  ,description:"Voucher Date" },    
    { id: "voucher_time", title: "Voucher Time",code:'[voucher_time]'  ,description:"Voucher Time" },    
    { id: "voucher_amount", title: "Voucher Amount",code:'[voucher_amount]'  ,description:"Voucher Amount" },
    { id: "first_name", title: "First Name",code:'[first_name]'  ,description:"First Name" },
    { id: "last_name", title: "Last Name",code:'[last_name]'  ,description:"Last Name" },
    { id: "course_name", title: "Course Name",code:'[course_name]'  ,description:"Course Name" },
    { id: "location_name", title: "Location Name",code:'[location_name]'  ,description:"Location Name" },
    { id: "sessions_dates", title: "Session Dates",code:'[sessions_dates]'  ,description:"Session Dates" },
    { id: "package_name", title:"Package Name",code:'[package_name]',description:"Package Name"},
    { id: "Token_number", title:"Tokens Number",code:'[Token_number]',description:"Tokens Number"},
    { id: "courses", title:"Package Courses",code:'[courses]',description:"Package Courses"},
    { id: "reset_link", title:"Reset Link",code:'[reset_link]',description:"Reset Link"},
    { id:  "new_password_link", title:"New Password Link", code:'[new_password_link]',description:"New Password Link"},   
    { id: "active_date",title:"Membership active date",code:'[active_date]',description:"Membership active date"}
];