import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Company } from 'models/Company';
import { FormControl } from '@angular/forms';
import { Subject, of } from 'rxjs';
import { ApiService } from 'app/_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, tap, switchMap, map, finalize, takeUntil } from 'rxjs/operators';
import { isObject } from 'util';
import { MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
  selector: 'course-selector',
  templateUrl: './course-selector.component.html',
  styleUrls: ['./course-selector.component.scss']
})
export class CourseSelectorComponent implements OnInit {
 
  @Input() autoClear=false;
  @Input() fullLoad=false;  
  @Input() currentCompany: Company;
  @Input() customerCompanyOnly=false;
  @Input('course') 
  set course(val){
    //console.log("set course =>",val);
    this.selectedCourseControl.patchValue(val);
  };
  @Output() courseSelected: EventEmitter<any> = new EventEmitter();;
  isLoading = false;
  filteredCourses = [];
  selectedCourseControl: FormControl;  
  courseSearchValue = '';
  onDestroy$ = new Subject<Boolean>();
  constructor(private apiSvc: ApiService,
    private toastySvc: ToastrService
  ) {
    this.selectedCourseControl = new FormControl();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
  }
  ngOnInit() {
    this.searchCourse();
  }

  displayFn(course) {
    if (course) {
      return course.name;
    }
  }
  searchCourse() {
    let params = {
      jsonQuery: {

      },
      'no-page': true,
      order: 'name',
      groups: ['courseSummary'],
    };
    
   
    this.selectedCourseControl.valueChanges
      .pipe(        
        debounceTime(1000),
        tap((value) => {
        this.isLoading = true;
        this.courseSearchValue = value;         
        })
        , switchMap((value) => {     
          if (this.currentCompany && this.courseSearchValue != '') {
            params.jsonQuery = {                           
                'billingCompany.id': this.currentCompany.id,
                name: {$contains: this.courseSearchValue}              
            }
          }
          if(this.courseSearchValue == '')
          params.jsonQuery = {                        
              'billingCompany.id': this.currentCompany.id,
              favourite: true            
          }
          if (!isObject(this.courseSearchValue) &&this.courseSearchValue!=null&&this.courseSearchValue.length>=3 ) {            
              return this.apiSvc.getCourses(params).pipe(
                map(response => response.data),
                finalize(() => this.isLoading = false))
          }
          else return of([]);
        }
        ),
        takeUntil(this.onDestroy$)
      ).subscribe((response: any) => {
        this.filteredCourses = response;
        this.isLoading = false;
      })
  }

  optionSelected(event: MatAutocompleteSelectedEvent) { 
    //console.log(event.option.value) 
    if (this.courseSelected)      
    {
        this.courseSelected.emit(event.option.value);
        if(this.autoClear)
          this.selectedCourseControl.patchValue(null);
      }

  }
  checkEmpty(value)
  {
    //console.log(value) 
    if(value=='' || value==null)  this.courseSelected.emit(null);

  }
 
}
