import { BookingParticipant, BookingParticipantStatus } from "models/BookingParticipant";
import { ChildOf } from "models/ChildOf";
import { Contact } from "models/Contact";
import { CourseRunBooking } from "models/CourseRunBooking";
import { FoodAllergy } from "models/FoodAllergy";
import { Invoice } from "models/Invoice";
import { Waiver } from "models/Waiver";

export class CourseRunParticipantsViewModel {
    contact:Contact;
    bookingParticipant:BookingParticipant;
    childOf:ChildOf;
    courseRunBooking:CourseRunBooking;
    invoice:Invoice;
    waivers:Waiver[];
    age:number;
    medicalConditions:string;
    isSwimmer:boolean;
    emergancyPhone:string;
    emergancyName:string;
    foodAllergy:FoodAllergy;
    signToken:string;
    leadBooker:boolean;
  // /**
  //    * @number Booking participant id 
  //    */
  //   id:number;
  //   status: BookingParticipantStatus;
  //   contactId: number;
  //   name: string;
  //   email: string;
  //   child: number;
  //   parent_id: null;
  //   courseRunBookingId:number;
  //   orderId: number;
  //   phone: string;
  //   mobile:string;
  //   waiverSignToken:string;
  //   signOutDate: string;
  //   signOutNotes: string;
  //   signInDate: string;
  //   signInTimeOfReturn: string;
  //   signInContactNumber: string;
  //   signInMissAction: string;
  //   invoiceId:number;
  //   invoiceOutstanding:number;
  //   /**
  //    * @string Booking participant status notes
  //    */
  //   notes:string;
  //   participants:[];
    
  }
  