import { Component, OnInit, Optional, Self } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material";
import { Moment } from "moment";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
})
export class DatePickerComponent implements ControlValueAccessor {
  _value: any;
  constructor(@Self() @Optional() public control: NgControl) {
    this.control.valueAccessor = this;
  }
  ngOnInit() {}
  onChange = (_: any) => {};
  onTouched = (_: any) => {};

  writeValue(obj: any): void {
    this._value = obj;
    this.onChange(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  handleOnChange(event: MatDatepickerInputEvent<Moment>) {
    this._value = event.value.format("YYYY-MM-DD");
    this.onTouched(true);
    this.onChange(this._value);
  }
}
