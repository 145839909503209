import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtraToolBookingViewModel } from 'app/viewModels';
import { environment } from 'environments/environment';
import { ExtraToolBooking } from 'models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiBookingOrderService {

  private baseUrl2: string;
  constructor(private http: HttpClient ) {
     this.baseUrl2 =   environment.api2Url+"/booking-order";
  }

  getBookingOrderExtras(bookingOrderId:number|string):Observable<ExtraToolBookingViewModel[]>{
    return this.http.get<ExtraToolBookingViewModel[]>(`${this.baseUrl2}/${bookingOrderId}/extras`);
  }

}
