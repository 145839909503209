import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import GiftVoucherViewModel from 'app/viewModels/GiftVoucherViewModel';
import { environment } from 'environments/environment';
import { GiftVoucher } from 'models/GiftVoucher';
import { GiftVoucherImage } from 'models/GiftVoucherImage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiGiftVouchersService {

  private giftVoucherUrl: string;
  private giftVoucherImagesUrl:string;
  constructor(private http: HttpClient ) {
     this.giftVoucherUrl =   environment.api2Url+"/gift-voucher";
     this.giftVoucherImagesUrl =   environment.api2Url+"/gift-voucher-images";

  }
  getVouchers(  filter:{page:number,
    per_page:number,
    code: string,
    startDate: string,
    endDate: string,
    requestedBy:string,
    assignedTo:string,
    amount:number}){
      const q= new HttpParams({fromObject:filter as any}).toString().replace(/null/g,"");
    return this.http.get<GiftVoucherViewModel[]>(`${this.giftVoucherUrl}?${q}`);
  }

  getVoucher(companyId:number, voucherId:number):Observable<GiftVoucherViewModel>{
    return this.http.get<GiftVoucherViewModel>(`${this.giftVoucherUrl}/${companyId}/${voucherId}`);
  }
  getVoucherImages():Observable<GiftVoucherImage[]>{
    return this.http.get<GiftVoucherImage[]>(`${this.giftVoucherImagesUrl}`);
  }


  updateGiftVoucher(
    giftVoucher: any
  ): Observable<GiftVoucher> {
    return this.http.put<GiftVoucher>(`${this.giftVoucherUrl}/${giftVoucher.company_id}/${giftVoucher.id}`,giftVoucher);
  }

}
