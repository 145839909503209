import {Contact} from './Contact';
import {Company} from './Company';
import {Serializable} from './Serializable';
import { PhysicalLocation } from './Location';
import { UserType } from 'app/_constants/UserType';

export class StaffMember implements Serializable<StaffMember> {
  id?: number;
  canTeach: boolean = null;
  startDate: Date = null;
  contact: Contact;
  primaryCompany: Company;
  secondaryCompany: Company;
  role: UserType;
  workLocations:PhysicalLocation[];
  constructor() {
    this.contact = new Contact();
    this.primaryCompany = new Company();
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.canTeach = input.canTeach;
    this.startDate = new Date(input.startDate);
    this.contact = new Contact().deserialize(input.contact);
    this.primaryCompany = new Company().deserialize(input.primaryCompany);
    if (input.secondaryCompany) {
      this.secondaryCompany = new Company().deserialize(input.secondaryCompany);
    }
    this.role = input.role;
    return this;
  }
}
