import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiWaiverService {
  private baseUrl: string;
  constructor(private authHttp: HttpClient,) {
    this.baseUrl = environment.apiUrl;

   }
  getWaivers(){
    return this.authHttp.get<any[]>(this.baseUrl +"/waivers")
    .pipe(
      map(res=>res.map(item=>{
        item.title=`${item.title} (${item.published?"Published":"Not Published"})`
        return item
      })) 
    );
  }

  getCustomerCourseWaiver(customerId,courseId){
    return this.authHttp.get<any[]>(this.baseUrl +`/waivers/customerCourseWaiver/${customerId}/${courseId}`);
  }
}
