import {UUID} from '../app/utils/Utils';
import {Serializable} from './Serializable';


export class PhysicalLocation implements Serializable<PhysicalLocation> {
  id: number | string;
  name: string;
  enabled = true;
  sendingEmailAddress: string;
  sendingSMTPAddress: string;
  sendingEmailUser: string;
  sendingEmailPassword: string;
  sendingReplyToName: string;
 

  constructor() {
    this.id = null;
    this.name = '';
  }

  deserialize(input) {
    if (!input) {
      return null;
    }

    this.id = input.id;
    this.name = input.name;
    this.enabled = input.enabled;
    this.sendingEmailAddress = input.sendingEmailAddress;
    this.sendingSMTPAddress = input.sendingSMTPAddress;
    this.sendingEmailUser = input.sendingEmailUser;
    this.sendingEmailPassword = input.sendingEmailPassword;
    this.sendingReplyToName = input.sendingReplyToName;

    return this;
  }
}


