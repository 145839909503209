export class StaffStatusColor {
    Working = '#608572';
    Available = '#0778BD';
    Holiday = '#8d131c';
    Unavailable = '#6f727a';
    Duty_Manager = '#dea0bf';
    Staff_Training = '#e3db9f';
    Maintenance = '#8fc9eb';
    Other = '#ba796d';
    lunchTime = '#FF8000';
    sick ="#FF6000";
    dayOff="#393646";
    availability = [
        {
            id: 1,
            title: 'Working',
            color: this.Working
        },
        {
            id: 2,
            title: 'Holiday',
            color: this.Holiday
        },
        {
            id: 3,
            title: 'Unavailable',
            color: this.Unavailable
        },
        {
            id: 4,
            title: 'Available',
            color: this.Available
        },
        {
            id: 5,
            title: 'Duty Manager',
            color: this.Duty_Manager
        },
        {
            id: 6,
            title: 'Staff Training',
            color: this.Staff_Training
        },
        {
            id: 7,
            title: 'Maintenance',
            color: this.Maintenance
        },
        {
            id: 8,
            title: 'Other',
            color: this.Other
        },
        {
            id: 11,
            title: 'DAY OFF',
            color: this.dayOff
        },
        {
            id: 12,
            title: 'Sick',
            color: this.sick
        },
        {
            id: 10,
            title: 'Lunch Break',
            color: this.lunchTime
        },
    ];
    getColor(statusId) {
        let st = parseInt(statusId);
        switch (st) {
            case 1:
                return this.Working;
            case 2:
                return this.Holiday;
            case 3:
                return this.Unavailable;
            case 4:
                return this.Available;
            case 5:
                return this.Duty_Manager;
            case 6:
                return this.Staff_Training;
            case 7:
                return this.Maintenance;
            case 8:
                return this.Other;
            case 10:
                return this.lunchTime;
            case 11:
                return this.dayOff;
            case 12:
                return this.sick;
        }
    }
    getTitle(statusId) {
        let st = parseInt(statusId);
        switch (st) {
            case 1:
                return "Working";
            case 2:
                return "Holiday";
            case 3:
                return "Unavailable";
            case 4:
                return "Available";
            case 5:
                return "Duty Manager";
            case 6:
                return "Staff Training";
            case 7:
                return "Maintenance";
            case 8:
                return "Other";
            case 10: //lunch
                return 'Lunch Break';
            case 11: //lunch
                return 'DAY OFF';
            case 12: //lunch
                return 'SICK';
        }
    }
}