<form #paymentForm="ngForm">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Payment</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="order || invoice">
    <div class="form-group">
      <label for="paymentMethod">Payment Method</label>
      <select name="paymentMethod" required class="form-control" id="paymentMethod"
              (change)="changedPaymentMethod($event.target.value)">
        <option  [selected]="paymentMethodSelected == payment_method"  [value]="payment_method" *ngFor="let payment_method of (paymentMethods$ | async)" >{{payment_method}}
        </option>
      </select>
    </div>
    <div class="form-group" *ngIf="paymentMethodSelected !== 'free'; else freePayment">
      <label for="amount">Amount</label>
      <div class="input-group">
        <span class="input-group-addon">$</span>
        <input type="number" step="0.01" id="amount" class="form-control" required [ngModel]="order.totalAmount"
               name="amount" *ngIf="order">
        <input type="number" step="0.01" id="amount" class="form-control" required [ngModel]="invoice.totalAmount"
               name="amount" *ngIf="invoice">
      </div>
      <div *ngIf="paymentForm.value.amount == 0">
        Invoice cannot be set to £0 - if you want to edit invoice go to Invoices and edit aa
      </div>
    </div>
    <ng-template #freePayment>
      <input type="hidden" name="amount" value="0">
    </ng-template>
    <div class="form-group" [class.hidden]="paymentMethodSelected != CourseBookingPaymentMethod.INVOICE">
      <label for="status">Status</label>
      <select name="status" required class="form-control" id="status" [ngModel]="paymentStatus">
        <option value="pending">
          Pending
        </option>
        <option value="paid" [disabled]="paymentMethodSelected == 'invoice'">
          Paid
        </option>
     
      </select>
    </div>

    <div class="form-group" *ngIf="paymentMethodSelected == CourseBookingPaymentMethod.INVOICE">
      <label for="dueDate">Due Date:</label>
      <input id="dueDate" name="dueDate" [ngModel]="dueDate" class="form-control" [matDatepicker]="datePicker"
             (focus)="datePicker.open()" (click)="datePicker.open()">
      <mat-datepicker #datePicker></mat-datepicker>
    </div>

    <div class="form-group">
      <label for="datePaid">Paid Date:</label>
      <input id="datePaid" name="datePaid" ngModel class="form-control" [matDatepicker]="datePaidPicker"
             (focus)="datePaidPicker.open()" (click)="datePaidPicker.open()">
      <mat-datepicker #datePaidPicker></mat-datepicker>
    </div>

    <div class="form-group">
      <label for="paymentRef">Payment Reference: (Optional)</label>
      <input id="paymentRef" class="form-control" ngModel name="paymentRef">
    </div>

    <div class="form-group">
      <label for="extraData">Extra Data: (Optional)</label>
      <input id="extraData" class="form-control" ngModel name="extraData">
    </div>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="savePayment(paymentForm)"
            [disabled]="paymentForm.value.amount == 0 || paymentForm.invalid">Save Payment
    </button>
    <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
