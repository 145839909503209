import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CourseSessionViewModel } from 'app/pages/attendance/viewModels/CourseSessionViewModel';
import { CourseRunParticipantsViewModel } from 'app/viewModels/CourseRunParticipantsViewModel';
import { ApiService } from 'app/_services/api.service';
 
@Component({
  selector: 'app-course-sign-out-modal',
  templateUrl: './course-sign-out-modal.component.html',
  styleUrls: ['./course-sign-out-modal.component.scss']
})
export class CourseSignOutModalComponent implements OnInit {
  _participant:CourseRunParticipantsViewModel;

  @Input() show:Boolean;
  @Output() onClose:EventEmitter<any>= new EventEmitter<any>() ;
  @Input() 
  set participant(value:CourseRunParticipantsViewModel){
    this._participant = value;
    console.log({participant:value});
    if(value)
      this.signOutForm.patchValue(value);
    if(value && value.courseRunBooking.signOutDate)
      this.signOutForm.patchValue({signOutDate:value.courseRunBooking.signOutDate.split(" ")[0]});

  }

  signOutForm:FormGroup;
  constructor(private apiSvc: ApiService,
    private _fb : FormBuilder

    ) {       
      this.signOutForm = this._fb.group({        
        signOutNotes:["",Validators.required],
        signOutDate:[""],
        courseRunBookingId:[null]
      });
  }

  ngOnInit() {
  }

  handleSignOut(){
    if(this.signOutForm.valid)
      this.apiSvc.signOutCourseRun(this._participant.courseRunBooking.id,this.signOutForm.value)
      .subscribe(res=>{
        this.show=false;        
        this.handleClose(res);
      })
  }

  handleClose(val){
    if(this.onClose)
    this.onClose.next(val);
  }

}
