import {InvoiceItem} from './InvoiceItem';
import {Company} from './Company';
import {Payment} from './Payment';
import {Serializable} from './Serializable';

export class Invoice implements Serializable<Invoice> {
  id: number;
  invoiceItems: InvoiceItem[];
  company: Company;
  payments: Payment[];
  date: Date;
  dueDate: Date;
  deleted:boolean;
  outstanding:number;
  constructor() {
    this.invoiceItems = [];
    this.payments = [];
  }

  deserialize(input) {
    if (!input) {
      return null;
    }

    this.id = input.id;
    if (input.invoiceItems) {
      this.invoiceItems = input.invoiceItems.map(item => {
        const invoiceItem = new InvoiceItem().deserialize(item);
        invoiceItem.invoice = this;
        return invoiceItem;
      });
    }
    this.company = new Company().deserialize(input.company);

    if (input.payments) {
      this.payments = input.payments.map(item => {
        const payment = new Payment().deserialize(item);
        payment.invoice = this;
        return payment;
      });
    }

    return this;
  }
}
