import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { PhysicalLocation } from 'models/Location';

@Injectable({
  providedIn: 'root'
})
export class ApiLocationsService {
  private baseUrl2: string;
  constructor(private http: HttpClient) {
    this.baseUrl2 = environment.api2Url + "/locations";
  }
  
  getLocations(){
    return this.http.get<PhysicalLocation[]>(
      `${this.baseUrl2}`
    );
  }
  getLocation(id:number){
    return this.http.get<PhysicalLocation>(
      `${this.baseUrl2}/${id}`
    );
  }
  updateLocation(location:PhysicalLocation){
    return this.http.put<PhysicalLocation>(
      `${this.baseUrl2}/${location.id}`,location
    );
  }
  createLocation(location:PhysicalLocation){
    return this.http.post<PhysicalLocation>(
      `${this.baseUrl2}`,location
    );
  }
}
