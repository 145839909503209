import {Company} from './Company';
import {UUID} from '../app/utils/Utils';
import {Serializable} from './Serializable';

export class SalesCategory implements Serializable<SalesCategory> {
  id: number | string;
  name: string;
  enabled = true;
  company: Company;
  sendReminderEmail: boolean;
  company_id: number;
  constructor() {
    this.id = UUID();
    this.company = new Company();
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.name = input.name;
    this.enabled = input.enabled;
    this.company = new Company().deserialize(input.company);
    return this;
  }
}
