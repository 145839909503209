import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { SalesCategory } from 'models/SalesCategory';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesCategoryService {
  private baseUrl: string;
  constructor(private http: HttpClient ) {
    this.baseUrl = environment.apiUrl;  
  }
  
  getCategoriesList(companyId:number): Observable<SalesCategory[]> {
    return this.http.get<SalesCategory[]>(`${this.baseUrl}/salesCategories/list/${companyId}`);
  }
}
